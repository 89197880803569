import { useEffect, useState } from "react";
import {
  Dashboard,
  Billing,
  MyCareerGuide,
  MyCoaches,
  Recommended,
  Explore,
  Schedule,
  TribajaLogo,
  ATS,
  AddResource,
  SidebarProfileIcon,
  BriefCase,
  People,
} from "../../assets/icons";
import { Avatar, Popover } from "antd";
import "./DashboardSidebar.scss";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { getTalentDashboard } from "../../redux/TalentSlice/TalentSlice";
import { getEmployerDashboard } from "../../redux/EmployerSlice/EmployerSlice";
import { getPartnerDashboard } from "../../redux/PartnerSlice/PartnerSlice";
import InitialsAvatar from "../InitialsAvatar/InitialsAvatar";
import image from "../../assets/images/premiumCard.png";

const DashboardSidebar = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);
  const [sidebarImg, setSidebarImg] = useState<any>("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const profileInfo: any = useSelector(
    (state: any) => state.profile.profileInfo
  );

  const location = useLocation();
  const navigate = useNavigate();

  const talentDashboard = useSelector((state: any) => state.talent.dashboard);
  const employerDashboard = useSelector(
    (state: any) => state.employer.dashboard
  );
  const partnerDashboard = useSelector((state: any) => state.partner.dashboard);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (
      (profileInfo?.role === "talent" || profileInfo?.role === "coach") &&
      talentDashboard
    ) {
      setSidebarImg(talentDashboard?.profile_image);
    } else if (profileInfo?.role === "employer" && employerDashboard) {
      setSidebarImg(employerDashboard?.profile_image);
    } else if (profileInfo?.role === "partner" && partnerDashboard) {
      setSidebarImg(partnerDashboard?.profile_image);
    }
  }, [talentDashboard, employerDashboard, partnerDashboard]);

  useEffect(() => {
    if (profileInfo?.role === "talent") {
      dispatch(getTalentDashboard());
    } else if (profileInfo?.role === "employer") {
      dispatch(getEmployerDashboard());
    } else if (profileInfo?.role === "partner") {
      dispatch(getPartnerDashboard());
    }
  }, [profileInfo]);

  const sidebarLinks: any = {
    talent: [
      {
        icon: <Dashboard />,
        name: "Dashboard",
        link: "/dashboard",
      },
      {
        icon: <Recommended />,
        name: "Recommended",
        link: "/recommendations",
      },
      {
        icon: <Explore />,
        name: "Explore",
        link: "/explore",
      },
    ],
    employer: [
      {
        icon: <Dashboard />,
        name: "Dashboard",
        link: "/dashboard",
        otherActiveRoutes: "/post/job",
      },
      {
        icon: <BriefCase />,
        name: "Jobs",
        link: "/jobs-posted",
        restricted: !employerDashboard?.subscription_status,
      },
      {
        icon: <Explore />,
        name: "Explore",
        link: "/explore",
        restricted:
          !employerDashboard?.subscription_status?.includes("scale") &&
          !employerDashboard?.subscription_status?.includes("grow"),
      },
      {
        icon: <ATS />,
        name: "ATS",
        link: "/ats",
        restricted: !employerDashboard?.subscription_status?.includes("grow"),
      },
      {
        icon: <People />,
        name: "Team Management",
        link: "/management",
        restricted:
          !profileInfo?.is_employer_staff &&
          !employerDashboard?.subscription_status,
      },
    ],
    partner: [
      {
        icon: <Dashboard />,
        name: "Dashboard",
        link: "/dashboard",
      },
      {
        icon: <AddResource />,
        name: "Add a Resource",
        link: "/add-resource",
      },
    ],
    admin: [],
  };

  const isEligibleForPremiumCard = (dashboard: any) => {
    return (
      dashboard?.profile?.id &&
      (dashboard?.subscription_status === "seeker" ||
        dashboard?.subscription_status === "start")
    );
  };

  const InitialsAvatarName =
    employerDashboard && employerDashboard?.profile?.company_name
      ? employerDashboard?.profile?.company_name
      : `${profileInfo?.first_name} ${profileInfo?.last_name}`;

  const renderNavLink = (item: any) => {
    if (item.restricted) {
      return (
        <Popover
          key={item.name}
          placement="right"
          content={
            <p>
              This is a premium feature, see our plans{" "}
              <Link to="/billing">here.</Link>
            </p>
          }
          trigger={["hover", "focus"]}
        >
          <div className="disabled-link">
            {item.icon}
            {item.name}
          </div>
        </Popover>
      );
    } else {
      return (
        <NavLink
          key={item.name}
          to={item.link}
          className={
            item?.otherActiveRoutes &&
            item?.otherActiveRoutes === location.pathname
              ? "active"
              : ""
          }
        >
          {item.icon}
          {item.name}
        </NavLink>
      );
    }
  };

  return (
    <AnimatePresence>
      {profileInfo?.first_name &&
        (!isMobileView ? (
          <motion.div
            transition={{ duration: 0.2 }}
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -20, opacity: 0 }}
            className="sidebar"
          >
            <TribajaLogo className="sidebar__logo" />
            <div className="sidebar__profile">
              <span>
                {sidebarImg ? (
                  <Avatar
                    style={{
                      width: "83px",
                      height: "83px",
                      border: "1px solid",
                    }}
                    src={sidebarImg}
                  />
                ) : (
                  <div className="profile__box-avatar">
                    <InitialsAvatar
                      name={InitialsAvatarName}
                      customStyles={{
                        fontSize: "22px",
                      }}
                    />
                  </div>
                )}
              </span>
              <p>
                {profileInfo?.first_name} {profileInfo?.last_name}
              </p>
            </div>
            <div className="sidebar__list">
              {profileInfo?.role &&
                sidebarLinks[profileInfo?.role].map((item: any) =>
                  renderNavLink(item)
                )}

              {/* {!profileInfo?.is_employer_staff ? (
                <NavLink to="/billing">
                  <Billing />
                  Billing
                </NavLink>
              ) : (
                ""
              )} */}
              {!profileInfo?.is_employer_staff &&
                profileInfo?.role !== "partner" && ( 
                  <NavLink to="/billing">
                    <Billing />
                    Billing
                  </NavLink>
                )}
              {profileInfo?.role === "talent" &&
                talentDashboard?.is_coach === true && (
                  <NavLink to="/calendly">
                    <Schedule />
                    Schedule
                  </NavLink>
                )}

              {profileInfo?.role === "talent" &&
                talentDashboard?.is_coach === true && (
                  <NavLink to="/coach-panel">
                    <SidebarProfileIcon />
                    Coach Panel
                  </NavLink>
                )}

              {!talentDashboard?.is_coach ? (
                profileInfo?.role === "talent" &&
                talentDashboard?.subscription_status === "trailblazer" ? (
                  <NavLink to="/my-coaches">
                    <MyCoaches />
                    My Coaches
                  </NavLink>
                ) : profileInfo?.role === "employer" ||
                  profileInfo?.role === "partner" ? null : (
                  <Popover
                    placement="right"
                    content={
                      <p>
                        This is a premium feature, see our plans{" "}
                        <Link to="/billing">here.</Link>
                      </p>
                    }
                    trigger={["hover", "focus"]}
                  >
                    <div className={"disabled-link"}>
                      <MyCoaches />
                      My Coaches
                    </div>
                  </Popover>
                )
              ) : null}

              {profileInfo?.role === "talent" &&
              (talentDashboard?.subscription_status === "path" ||
                talentDashboard?.subscription_status === "trailblazer") ? (
                <NavLink className={"career-guide"} to="/career-guide">
                  <MyCareerGuide />
                  My Career Guide
                </NavLink>
              ) : profileInfo?.role === "employer" ||
                profileInfo?.role === "partner" ? null : (
                <Popover
                  placement="right"
                  content={
                    <p>
                      This is a premium feature, see our plans{" "}
                      <Link to="/billing">here.</Link>
                    </p>
                  }
                  trigger={["hover", "focus"]}
                >
                  <div className={"disabled-link"}>
                    <MyCareerGuide />
                    My Career Guide
                  </div>
                </Popover>
              )}
              {isEligibleForPremiumCard(talentDashboard) && (
                <div
                  onClick={() => navigate("/billing")}
                  className="premium-card-div"
                >
                  <img
                    className="premium-card"
                    src={image}
                    alt="premium card"
                  />
                </div>
              )}
              {isEligibleForPremiumCard(employerDashboard) && (
                <div
                  onClick={() => navigate("/billing")}
                  className="premium-card-div"
                >
                  <img
                    className="premium-card"
                    src={image}
                    alt="premium card"
                  />
                </div>
              )}
            </div>
          </motion.div>
        ) : (
          <>
            {/* Mobile view: bottom navigation bar        */}
            <div className="bottom-nav">
              {profileInfo?.role &&
                sidebarLinks[profileInfo?.role].map((item: any) =>
                  renderNavLink(item)
                )}
              {profileInfo?.role === "talent" &&
                talentDashboard?.is_coach === true && (
                  <NavLink to="/calendly">
                    <Schedule />
                    Schedule
                  </NavLink>
                )}

              {profileInfo?.role === "talent" &&
                talentDashboard?.is_coach === true && (
                  <NavLink to="/coach-panel">
                    <SidebarProfileIcon />
                    Coach Panel
                  </NavLink>
                )}

              {!talentDashboard?.is_coach ? (
                profileInfo?.role === "talent" &&
                talentDashboard?.subscription_status === "trailblazer" ? (
                  <NavLink to="/my-coaches">
                    <MyCoaches />
                    My Coaches
                  </NavLink>
                ) : profileInfo?.role === "employer" ||
                  profileInfo?.role === "partner" ? null : (
                  <Popover
                    placement="right"
                    content={
                      <p>
                        This is a premium feature, see our plans{" "}
                        <Link to="/billing">here.</Link>
                      </p>
                    }
                    trigger={["hover", "focus"]}
                  >
                    <div className={"disabled-link"}>
                      <MyCoaches />
                      My Coaches
                    </div>
                  </Popover>
                )
              ) : null}

              {profileInfo?.role === "talent" &&
              (talentDashboard?.subscription_status === "path" ||
                talentDashboard?.subscription_status === "trailblazer") ? (
                <NavLink className={"career-guide-1"} to="/career-guide">
                  <MyCareerGuide />
                  My Career Guide
                </NavLink>
              ) : profileInfo?.role === "employer" ||
                profileInfo?.role === "partner" ? null : (
                <Popover
                  placement="right"
                  content={
                    <p>
                      This is a premium feature, see our plans{" "}
                      <Link to="/billing">here.</Link>
                    </p>
                  }
                  trigger={["hover", "focus"]}
                >
                  <div className={"disabled-link"}>
                    <MyCareerGuide />
                    My Career Guide
                  </div>
                </Popover>
              )}
            </div>
          </>
        ))}
    </AnimatePresence>
  );
};

export default DashboardSidebar;
