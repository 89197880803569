import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import ProfileCover from "../../../assets/images/profileCover.png";
import MentorImage from "../../../assets/images/mentor.png";

import { ReactComponent as LocationIcon } from "../../../assets/icons/locationIcon.svg";
import { ReactComponent as WriteReview } from "../../../assets/images/writeReview.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/editProfileIcon.svg";

import { ReactComponent as PlusIcon } from "../../../assets/icons/plusIcon.svg";
import SocialMediaIcons from "../../../assets/icons/SocialMediaIcons";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verifiedIcon.svg";
import { ReactComponent as BookmarkIcon } from "../../../assets/icons/bookmark.svg";
import { ReactComponent as StarIcon } from "../../../assets/icons/starIcon.svg";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Tag,
  Button,
  Tabs,
  Progress,
  Upload,
  Rate,
  Image,
  GetProp,
  message,
} from "antd";
import type { TabsProps } from "antd";
import { Link } from "react-router-dom";

import EditProfileModal from "../Modals/EditProfileModal";
import { AppDispatch } from "../../../redux/store/store";

import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";

import {
  getEmployerLogoPic,
  getEmployerPublicProfile,
} from "../../../redux/EmployerSlice/EmployerSlice";
import { TailSpin } from "react-loader-spinner";
import "./Profile.scss";

import { benefitsEmojisMapping } from "../../../utils/benefitsEmojiMapping";
import Slider from "react-slick";
import DashboardNavbar from "../../../components/DashboardNavbar/DashboardNavbar";
import InitialsAvatar from "../../../components/InitialsAvatar/InitialsAvatar";
import PostedJobCard from "../../../components/PostedJobCard/PostedJobCard";
import RequestForDeleteModal from "../../../components/RequestForDeleteModal/RequestForDeleteModal";
import RequestForLeaveModal from "../../../components/RequestForLeaveModal/RequestForLeaveModal";
import axios from "axios";
import api from "../../../utils/api";
import { getAccessToken } from "../../../utils/jwtToken";
import Cookies from "js-cookie";
import ReviewModal from "../../../components/ReviewModal/ReviewModal";

type ProfileParams = {
  userType: "talent" | "coach" | "employer" | "partner";
  userId: string;
};

type Props = {
  // typing of data provided by parent
};

type DetailObject = {
  title: string;
  desc: string;
};
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const EmployerProfile: React.FC = (props: Props) => {
  const params = useParams();

  const employerId: string | undefined = params.userId;

  const dispatch = useDispatch<AppDispatch>();
  const employerProfile = useSelector(
    (state: any) => state.employer.profileInfo
  );

  const profileInfo: any = useSelector(
    (state: any) => state.profile.profileInfo
  );

  const employerDashboard = useSelector(
    (state: any) => state.employer.dashboard
  );

  const employerProfileReviews = useSelector(
    (state: any) => state.employer.reviews
  );

  const [employerReviews, setEmployerReviews] = useState<[]>([]);

  const postedJobs = employerDashboard?.job_statistics?.open_roles?.analytics;

  const [isFollowed, setIsFollowed] = useState(employerProfile?.is_following);
  // const [s3UploadUrl, setS3UploadUrl] = useState<string>("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [reviewModal, setReviewModal] = useState(false);
  // const [fileListDisplay, setFileListDisplay] = useState<UploadFile[]>([]);
  // const [fileId, setFileId] = useState('');
  // const [key, setKey] = useState<string>("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);
  const [customStyleForNav, setCustomStyleForNav] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobileView) {
      setCustomStyleForNav({
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "unset",
        padding: "20px 24px",
      });
    } else {
      setCustomStyleForNav({
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "unset",
        padding: "20px 80px",
      });
    }
  }, []);

  const followEmployer = async () => {
    setIsFollowed(true);
    await axios
      .post(
        `${api}/talent/follow_employer/`,
        {
          talent: Cookies.get("id"),
          employer: employerProfile?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      )
      .then((res) => {
        dispatch(getEmployerPublicProfile(employerId));
        setIsFollowed(true);
      })
      .catch((err) => {
        setIsFollowed(false);
      });
  };
  const unfollowEmployer = async () => {
    setIsFollowed(false);
    await axios
      .request({
        method: "DELETE",
        url: `${api}/talent/follow_employer/`,
        data: {
          employer_id: employerProfile?.id,
        },
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((res) => {
        dispatch(getEmployerPublicProfile(employerId));
        setIsFollowed(false);
      })
      .catch((err) => {
        setIsFollowed(true);
      });
  };

  useEffect(() => {
    if (employerId) {
      dispatch(getEmployerPublicProfile(employerId)).then((e) => {
        setIsFollowed(e.payload?.is_following);
      });
      if (!employerProfile?.s3_params) dispatch(getEmployerLogoPic(employerId));
    }
  }, []);

  const handleReviewModal = () => {
    setReviewModal((prevState) => !prevState);
  };
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const WriteReviewTile = () => {
    return (
      <div
        className="job-tile"
        style={{
          background: "#FBAD84",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "334px",
          marginTop: "40px",
        }}
      >
        {/* Review Paragraph */}

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginTop: "30px",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#1E1E2F",
            }}
          >
            {"Write a Review"}
          </div>

          <div
            style={{
              marginTop: "10px",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              color: "#344054",
              width: "320px",
            }}
          >
            {
              "Share your experience and help others uncover opportunities, connections, and growth. Your feedback empowers our tech community."
            }
          </div>

          <div style={{ display: "flex", marginTop: "12px" }}>
            <Button
              type="primary"
              shape="round"
              size={"large"}
              style={{
                padding: "6px 44px",
                fontSize: "14px",
                fontWeight: 700,
                background: "none",
                color: "#1E1E2F",
                border: "0.5px solid",
              }}
              onClick={handleReviewModal}
            >
              Write a Review
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "-10px",
            }}
          >
            <WriteReview />
          </div>
        </div>
      </div>
    );
  };

  const ReviewTile = ({ reviewObj }: any) => {
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "334px",
          marginTop: "40px",
        }}
      >
        {/* Review Paragraph */}
        <div
          className="review-paragraph"
          style={{
            marginTop: "30px",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "30px",
            color: "#111111",
            width: "320px",
            wordWrap: "break-word",
            // overflowWrap: "break-word",
          }}
        >
          {reviewObj.review}
        </div>

        {/* Review Rating */}
        <div
          className="review-rating"
          style={{
            marginTop: "30px",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "30px",
            color: "#111111",
            width: "320px",
          }}
        >
          <Rate value={reviewObj.rating} />
        </div>

        <div
          className="review-avatar-sec"
          style={{
            display: "flex",
            width: "100%",
            marginTop: "40px",
            justifyContent: "space-around",
            alignItems: "center",
            // marginLeft: "30px",
          }}
        >
          {reviewObj.user?.profile_pic ? (
            <Avatar
              style={{ width: "70px", height: "70px" }}
              src={reviewObj.user?.profile_pic}
            />
          ) : (
            <InitialsAvatar
              name={reviewObj.user?.full_name}
              customStyles={{ width: "70px", height: "70px" }}
            />
          )}

          <div
            style={{
              display: "flex",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "20px",
              color: "#344054",
              // marginTop: "20px",
              alignItems: "baseline",
              flexDirection: "column",
              marginRight: "50px",
            }}
          >
            {reviewObj.user?.full_name || ""}
            <div
              style={{
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "20px",
                color: "#6F6C90",
                marginTop: "4px",
                alignItems: "center",
              }}
            >
              {" "}
              {reviewObj.talent_latest_career?.company}{" "}
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#6F6C90",
                marginTop: "4px",
                alignItems: "center",
              }}
            >
              {" "}
              {reviewObj.talent_latest_career?.position}{" "}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const LifeAtCompanyTile = ({ jobObj, index }: any) => {
    const handleDelete = async () => {
      try {
        console.log("api call");

        const response = await axios.delete(
          `${api}/employer/file/delete/${index}/`,
          {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
            },
          }
        );
        dispatch(getEmployerPublicProfile(employerId));

        console.log("API response:", response);

        message.success("Image deleted successfully");
      } catch (error) {
        console.error("Error deleting the image:", error);
        message.error("Failed to delete the image");
      }
    };

    return (
      <>
        <div style={{ position: "relative", display: "inline-block" }}>
          <Image width={175} height={"fit-content"} src={jobObj} />
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
              color: "red",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              padding: "2px 8px",
              borderRadius: "50%",
            }}
            onClick={handleDelete}
          >
            X
          </div>
        </div>
      </>
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const S3FileUploadHandler = async ({ file, onSuccess }: any) => {
    try {
      //get file id from backend
      const { data } = await handleUploadGallery("gallery");
      const fileToUpload = fileList.find((f) => f.uid === file.uid);

      const formData = new FormData();
      formData.append("key", data?.s3_params?.fields?.key);
      formData.append("file", fileToUpload?.originFileObj as RcFile);

      //upload to S3 bucket

      const s3UploadResponse = await fetch(data?.s3_params?.url, {
        method: "POST",
        body: formData,
      });

      //confirm to backend that data is uploaded to S3
      const backendConfirmationResponse = await axios.post(
        `${api}/employer/file/upload/confirm/`,
        {
          id: data.file_id,
        },
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );

      // return backendConfirmationResponse
      onSuccess(backendConfirmationResponse);
    } catch (error) {
      console.error("error inside the S3FileUploadHandler", error);
    }
  };

  const handleUploadGallery = (fileType: string) =>
    axios.post(
      `${api}/employer/file/upload/`,
      {
        file_type: fileType,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );

  const UploadTile = () => {
    return (
      <div
        className="job-tile"
        style={{
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "294px",
          height: "238px",
          border: "1px dotted",
          justifyContent: "center",
        }}
      >
        <div style={{ marginTop: "30px" }}>
          <PlusIcon />
        </div>
        <div
          style={{
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "18x",
            marginTop: "40px",
          }}
        >
          Drag pictures here to{" "}
          <a
            href="/"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              // message.error("Not implemented yet");
            }}
          >
            {" "}
            upload or browse{" "}
          </a>
        </div>
      </div>
    );
  };

  const JobTile = ({ jobObj }: any) => {
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <div style={{display: "flex", flexDirection: "column", alignItems: 'center'}}> */}

        {/* Job Corners logos */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Progress
            type="circle"
            percent={jobObj.percentageMatch}
            size={40}
            style={{ marginLeft: "10px" }}
            strokeColor="#5937CC"
          />

          <BookmarkIcon style={{ marginRight: "20px" }} />
        </div>

        {/* JobImage */}
        <Avatar
          style={{
            width: "78px",
            height: "78px",
            border: "1px solid #FBAD84",
            marginTop: "30px",
          }}
          src={jobObj.logoImage}
        />

        {/* Company Name */}

        <div
          style={{
            display: "flex",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20.42px",
            color: "#344054",
            marginTop: "4px",
            alignItems: "center",
          }}
        >
          {jobObj.companyName}
          <VerifiedIcon style={{ marginLeft: "10px" }} />
        </div>

        {/* JobStars */}

        <div
          style={{
            display: "flex",
            marginTop: "6px",
            width: "100px",
            justifyContent: "space-between",
          }}
        >
          {new Array(jobObj.companyStars).fill(1).map(() => {
            return <StarIcon />;
          })}
        </div>

        {/* Job Title */}

        <div
          style={{
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "24px",
            color: "#1E1E2F",
            marginTop: "6px",
          }}
        >
          {jobObj.jobTitle}
        </div>

        <hr style={{ marginTop: "20px", width: "85%" }} />

        {/* location and minor details */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "92%",
            marginTop: "6px",
          }}
        >
          <div
            style={{
              color: "#344054",
              fontSize: "12px",
              lineHeight: "15.31px",
              fontWeight: 400,
              display: "flex",
            }}
          >
            <LocationIcon style={{ marginRight: "2px" }} /> {jobObj.location}
          </div>

          <div
            style={{
              color: "#344054",
              fontSize: "12px",
              lineHeight: "15.31px",
              fontWeight: 400,
              display: "flex",
            }}
          >
            <LocationIcon style={{ marginRight: "2px" }} /> {jobObj.jobType}
          </div>

          <div
            style={{
              color: "#344054",
              fontSize: "12px",
              lineHeight: "15.31px",
              fontWeight: 400,
              display: "flex",
            }}
          >
            <LocationIcon style={{ marginRight: "2px" }} /> {jobObj.updated}
          </div>
        </div>

        {/* Action Buttons */}

        <div style={{ display: "flex", marginTop: "20px" }}>
          <Button
            type="primary"
            shape="round"
            size={"large"}
            style={{
              width: "170px",
              padding: "6px 44px",
              fontSize: "14px",
              fontWeight: 600,
              background: "#1E1E2F",
            }}
          >
            Apply
          </Button>
          <Button
            type="primary"
            shape="round"
            size={"large"}
            style={{
              width: "170px",
              marginLeft: "20px",
              padding: "6px 44px",
              fontSize: "14px",
              fontWeight: 600,
              background: "none",
              color: "#1E1E2F",
              border: "0.5px solid",
            }}
          >
            View Job
          </Button>
        </div>

        {/* </div> */}
      </div>
    );
  };

  const { userId, userType } = useParams<ProfileParams>();
  const navigate = useNavigate();

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  const [_width, setWidth] = useState<number | null>();
  const [selectedTab, setSelectedTab] = useState<string | null>("overview");
  const [openModal, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const _clientWidth = document.getElementById("tabs")?.clientWidth;

    if (_clientWidth) setWidth(_clientWidth);
  }, document.getElementById("tabs")?.clientWidth as any);

  const ReviewsContent: React.FC = () => {
    const reviews = new Array(6).fill({
      reviewerImage: MentorImage,
      name: "Adam Smith",
      jobTitle: "UI/UX Designer at LinkedIn",
      reviewComment:
        '"Thank you Tribaja for connecting me with Spotify — I was hired as a Senior Designer and so far it’s been an amazing experience!"',
    });

    return (
      <div id="MainDiv" style={{ display: "flex", marginBottom: "20px" }}>
        <div
          id="Mentor-tiles"
          className="reviews-section"
          // style={{ marginTop: "40px", justifyContent: "space-evenly" }}
        >
          {isPublicView && <WriteReviewTile />}
          {reviewModal && (
            <ReviewModal
              handleReviewModal={handleReviewModal}
              employerId={employerProfile?.id}
            />
          )}
          {employerProfile?.reviews?.length > 0 &&
            employerProfile?.reviews?.map((review: any) => (
              <ReviewTile reviewObj={review} />
            ))}
        </div>
      </div>
    );
  };

  const OverViewContent: React.FC = () => {
    const numberOfEmployees =
      employerProfile?.demographics?.demographics?.company_size;
    const males =
      employerProfile?.demographics?.demographics?.number_of_male_employees ??
      0;
    const females =
      employerProfile?.demographics?.demographics?.number_of_female_employess ??
      0;
    const nonBinary =
      employerProfile?.demographics?.demographics
        ?.number_of_non_binary_employees ?? 0;

    let demographicsDisplay;

    if (males || females || nonBinary) {
      demographicsDisplay = `${males}:${females}:${nonBinary}`;
    } else {
      demographicsDisplay = "---------";
    }

    console.log(demographicsDisplay);

    const industries = employerProfile?.industries?.industries || [];

    return (
      <div id="MainDiv" style={{ display: "flex" }}>
        <div
          id="MainDivFirstHalf"
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "700px",
            marginTop: "12px",
            // width: "50%",
          }}
        >
          <div id="About">
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              About Company{" "}
            </div>

            <p
              style={{
                marginTop: "16px",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {employerProfile?.demographics?.demographics
                ?.company_description || "No details about company.."}
            </p>
          </div>

          <div id="About" style={{ marginTop: "20px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Commitment to diversity{" "}
            </div>

            <p
              style={{
                marginTop: "16px",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {
                employerProfile?.demographics?.demographics
                  ?.diversity_commitment
              }
            </p>
          </div>

          <div id="About" style={{ marginTop: "20px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Inclusion{" "}
            </div>

            <p
              style={{
                marginTop: "16px",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {employerProfile?.demographics?.demographics?.why_inclusion ||
                "...."}
            </p>
          </div>

          <hr className="horizontal-line-break" />

          <div id="Industries" style={{ marginTop: "30px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Industry{" "}
            </div>

            <div
              id="skills"
              style={{
                marginTop: "10px",
                display: "flex",
                marginLeft: "-10px",
              }}
            >
              {industries?.length > 0 ? (
                <div>
                  {industries.map((ind: string, index: number) => (
                    <Tag
                      key={index}
                      color="#FDCEB5"
                      style={{
                        width: "fit-content",
                        borderRadius: "16px",
                        color: "#344054",
                        padding: "4px 12px 4px 12px",
                        fontSize: "14px",
                        marginTop: "10px",
                        paddingTop: "1px",
                        paddingBottom: "2px",
                        marginLeft: "10px",
                      }}
                    >
                      {ind}
                    </Tag>
                  ))}
                </div>
              ) : (
                <p
                  style={{
                    marginTop: "16px",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "20.42px",
                  }}
                >
                  No industies added...
                </p>
              )}
            </div>
          </div>

          <hr className="horizontal-line-break" />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "24px",
              flexWrap: "wrap",
              width: '405px',
              rowGap: '0px'
            }}
          >
            <div
              id="employeesCount"
              style={{ marginTop: "40px", marginBottom: "40px" }}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  marginBottom: "08px",
                }}
              >
                {" "}
                Company size
              </p>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20.42px",
                }}
              >
                {" "}
                {numberOfEmployees}
              </div>
            </div>
            <div
              // id="employeesCount"
              style={{ marginTop: "40px", marginBottom: "40px" }}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  marginBottom: "08px",
                }}
              >
                {" "}
                Gender Diversity (male:female:non-binary)
              </p>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20.42px",
                }}
              >
                {" "}
                {demographicsDisplay}
              </div>
            </div>
            <div
              // id="employeesCount"
              style={{ marginBottom: "40px" }}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  marginBottom: "08px",
                }}
              >
                {" "}
                Stage of Organization
              </p>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20.42px",
                }}
              >
                {" "}
                {employerProfile?.demographics?.demographics
                  ?.stage_of_organization || "---------"}
              </div>
            </div>
          </div>
        </div>

        {/* LINE */}
        <div className="vertical-line" />

        {/*  2ND RIGHT HALF SECTION*/}

        <div
          id="MainDivSecondHalf"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "24px",
            marginLeft: "42px",
            width: "50%",
          }}
        >
          <div
            id="benefits"
            style={{ marginTop: "12px", marginBottom: "20px" }}
          >
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Company Highlights{" "}
            </div>

            <div
              id="skills"
              style={{
                marginTop: "10px",
                display: "flex",
                marginLeft: "-10px",
              }}
            >
              <div>
                {employerProfile?.demographics?.demographics?.company_highlights
                  .length ? (
                  employerProfile?.demographics?.demographics?.company_highlights.map(
                    (highlight: any) => (
                      <Tag
                        color="#EDEEF7"
                        style={{
                          width: "fit-content",
                          borderRadius: "16px",
                          color: "#344054",
                          padding: "8px",
                          fontSize: "14px",
                          marginTop: "20px",
                          marginLeft: "10px",
                        }}
                      >
                        {highlight}
                      </Tag>
                    )
                  )
                ) : (
                  <p
                    style={{
                      marginTop: "16px",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "20.42px",
                      marginLeft: "10px",
                    }}
                  >
                    No company highlights mentioned.
                  </p>
                )}
              </div>
            </div>
            <hr className="horizontal-line-break" />
            <div
              id="benefits"
              style={{ marginTop: "24px", marginBottom: "20px" }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20.42px",
                }}
              >
                {" "}
                Company Benefits{" "}
              </div>

              <div
                id="skills"
                style={{
                  marginTop: "10px",
                  display: "flex",
                  marginLeft: "-10px",
                }}
              >
                <div>
                  {employerProfile?.demographics?.demographics?.company_benefits
                    .length ? (
                    employerProfile?.demographics?.demographics?.company_benefits.map(
                      (benefit: any) => (
                        <Tag
                          color="#EDEEF7"
                          style={{
                            width: "fit-content",
                            borderRadius: "16px",
                            color: "#344054",
                            padding: "8px",
                            fontSize: "14px",
                            marginTop: "20px",
                            marginLeft: "10px",
                          }}
                        >
                          {benefit}
                        </Tag>
                      )
                    )
                  ) : (
                    <p
                      style={{
                        marginTop: "16px",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "20.42px",
                        marginLeft: "10px",
                      }}
                    >
                      No Company Benefits is mentioned.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "overview",
      label: "Overview",
      children: <OverViewContent />,
      style: { fontFamily: "Space Grotesk" },
    },
    {
      key: "reviews",
      label: "Reviews",
      children: <ReviewsContent />,
      style: { fontFamily: "Space Grotesk" },
    },
  ];

  const jobs = employerProfile?.positions_available?.jobs ?? [];

  const isPublicView = employerId !== employerDashboard?.profile?.id;

  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteAccount = () => {
    setModalOpen(false);
    setOpenLeaveModal(true);
  };
  const handleContinueDelete = () => {
    setOpenDeleteModal(true);
    setOpenLeaveModal(false);
  };

  const handleCancelDelete = () => {
    setOpenLeaveModal(false);
    setOpenDeleteModal(false);
  };
  const locationOfWork =
    employerProfile?.demographics?.demographics?.location_of_work || "------";
  const locationArray = locationOfWork
    .split(",")
    .map((item: any) => item.trim());
  const cityAndState = `${locationArray[2]}, ${locationArray[1]}`;

  return (
    <>
      {openDeleteModal && (
        <RequestForDeleteModal handleCancelDelete={handleCancelDelete} />
      )}
      {openLeaveModal && (
        <RequestForLeaveModal
          handleCancelDelete={handleCancelDelete}
          handleContinueDelete={handleContinueDelete}
        />
      )}
      {openModal && (
        <EditProfileModal
          handleDeleteAccount={handleDeleteAccount}
          formModalType={"EMPLOYEE_FORM"}
          onModalClose={() => setModalOpen(false)}
        />
      )}
      {((employerProfile?.id && employerDashboard) || isPublicView) && (
        <DashboardNavbar addLogo={true} customStyles={customStyleForNav} />
      )}
      {employerProfile?.id ? (
        <div className="talent-profile">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div id="CoverImageDiv">
              <img
                className="profile-banner-img"
                src={ProfileCover}
                alt="ProfileCover"
              />
            </div>

            <div
              id="ProfileTopSectionDiv"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "200px",
                marginLeft: "200px",
              }}
            >
              <div className="user-avatar" style={{ display: "flex" }}>
                <div
                  className="profile-image-avatar-div"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    alignItems: "center",
                    marginTop: "-70px",
                  }}
                >
                  {employerProfile?.s3_params ? (
                    <Avatar
                      className="profile-image-avatar"
                      style={{
                        width: "200px",
                        height: "205px",
                        border: "3px solid #FBAD84",
                      }}
                      src={employerProfile?.s3_params || null}
                      icon={<UserOutlined />}
                      size={200}
                    />
                  ) : (
                    <div className="box-avatar">
                      <InitialsAvatar
                        name={
                          employerProfile?.company_name ??
                          `${profileInfo?.first_name} ${profileInfo?.last_name}`
                        }
                        customStyles={{
                          fontSize: "38px",
                        }}
                      />
                    </div>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "baseline",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      marginLeft: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <p className="font-class talent-profile-name">
                      {" "}
                      {employerProfile?.company_name ??
                        `${profileInfo?.first_name} ${profileInfo?.last_name}`}{" "}
                    </p>
                    <div style={{ display: "flex", alignItems: "end" }}>
                      {employerProfile?.is_verified && (
                        <VerifiedIcon style={{ marginLeft: "10px" }} />
                      )}
                      <p
                        style={{ marginLeft: "10px", width: "80px" }}
                        className="font-class-gender"
                      >
                        {" "}
                        Tribaja Verified{" "}
                      </p>
                    </div>
                  </div>

                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <LocationIcon />{" "}
                    <p
                      style={{ marginLeft: "10px", whiteSpace: "nowrap" }}
                      className="location-state"
                    >
                      {/* {employerProfile?.demographics?.demographics
                        ?.location_of_work || "------"} */}
                      {cityAndState}
                    </p>
                  </div>

                  {!isPublicView && (
                    <div
                      className="job-label"
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "column",
                        width: "200px",
                      }}
                    >
                      {/* <Tag color="#FEEDCC" style={{ borderRadius: '12px', color: '#1E1E2F', paddingLeft: '10px', paddingRight: '10px'  }}> UI/UX DESIGNER </Tag> */}
                      <Progress
                        percent={employerDashboard?.benchmark_score || 0}
                        size={[180, 10]}
                      />
                      <LocationIcon />{" "}
                      <p
                        style={{ width: "auto", fontSize: "12px" }}
                        className="location-state"
                      >
                        Benchmark Score
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div
                id="SocialMediaAndButtonDiv"
                style={{ display: "flex", marginTop: "40px" }}
              >
                <div
                  id="SocialMediaIcons"
                  style={{
                    display: "flex",
                    marginRight: "20px",
                    marginTop: "4px",
                  }}
                >
                  {employerProfile?.demographics?.links?.twitter && (
                    <Link
                      to={employerProfile?.demographics?.links?.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialMediaIcons.twitter
                        style={{ marginRight: "10px" }}
                      />
                    </Link>
                  )}
                  {employerProfile?.demographics?.links?.linkedin && (
                    <Link
                      to={employerProfile?.demographics?.links?.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialMediaIcons.linkedin
                        style={{ marginRight: "10px" }}
                      />
                    </Link>
                  )}
                  {employerProfile?.demographics?.links?.website && (
                    <Link
                      to={employerProfile?.demographics?.links?.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialMediaIcons.website
                        style={{ marginRight: "10px" }}
                      />
                    </Link>
                  )}
                </div>

                <div id="ButtonSection">
                  {isPublicView && (
                    <Button
                      onClick={
                        !employerProfile?.is_following
                          ? followEmployer
                          : unfollowEmployer
                      }
                      type="primary"
                      shape="round"
                      size={"large"}
                      style={{
                        padding: "6px 44px",
                        fontSize: "14px",
                        fontWeight: 600,
                        background: "#1E1E2F",
                      }}
                    >
                      {!isFollowed ? "Follow" : "Unfollow"}
                    </Button>
                  )}

                  {!isPublicView && (
                    <Button
                      type="primary"
                      shape="round"
                      size={"large"}
                      style={{
                        marginLeft: "20px",
                        padding: "6px 44px",
                        fontSize: "14px",
                        fontWeight: 600,
                        background: "none",
                        color: "#1E1E2F",
                        border: "0.5px solid",
                      }}
                      onClick={() => setModalOpen(true)}
                    >
                      Edit Profile
                    </Button>
                  )}
                </div>

                <div id="ButtonSectionMobile">
                  <button
                    onClick={() => (!isPublicView ? setModalOpen(true) : "")}
                    className="editProfile-btn"
                  >
                    {!isPublicView ? <EditIcon /> : ""}
                  </button>
                </div>
              </div>
            </div>

            {/* Tabs Section */}

            <div
              className="tabs_container"
              style={{
                marginTop: "100px",
                marginLeft: "200px",
                marginRight: "200px",
              }}
            >
              <Tabs
                id="tabs"
                defaultActiveKey="overview"
                items={items}
                onChange={onChange}
              />
              {selectedTab === "overview" && (
                <hr
                  className="horizontal-line-break"
                  style={{ marginTop: "unset" }}
                />
              )}

              {/* Opportunities Section  */}

              {selectedTab === "overview" && (
                <div
                  id="opportunities"
                  style={{ marginTop: "100px", marginBottom: "20px" }}
                >
                  <div id="job" style={{ marginTop: "50px" }}>
                    <div
                      className="opportunities-heading"
                      style={{
                        fontWeight: 600,
                        fontSize: "24px",
                        lineHeight: "20.42px",
                        marginBottom: "40px",
                      }}
                    >
                      {" "}
                      {(employerProfile?.demographics?.gallery?.length > 0 ||
                        !isPublicView) &&
                        "Life At Company"}{" "}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* {!isPublicView && <UploadTile />} */}
                      {!isPublicView && (
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          onChange={handleChange}
                          onPreview={handlePreview}
                          multiple={true}
                          onRemove={() => {
                            console.log("Attempting to remove file:");
                            return true;
                          }}
                          customRequest={S3FileUploadHandler}
                          accept=".jpg, .pdf, .png"
                        >
                          {fileList.length >= 10 ? null : uploadButton}
                        </Upload>
                      )}
                      {previewImage && (
                        <Image
                          wrapperStyle={{ display: "none" }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) =>
                              setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage(""),
                          }}
                          src={previewImage}
                        />
                      )}

                      <div
                        style={{
                          display: "flex",
                          gap: "16px",
                          marginTop: 16,
                          overflowX: "auto",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        {Object.entries(employerProfile?.gallery || {}).map(
                          (img) => (
                            <LifeAtCompanyTile jobObj={img[1]} index={img[0]} />
                          )
                        )}
                      </div>

                      {/* {isPublicView &&
                        employerProfile?.demographics?.gallery?.map(
                          (img: any) => <LifeAtCompanyTile jobObj={img} />
                        )} */}
                    </div>
                  </div>

                  {/* Jobs */}
                  {employerDashboard?.subscription_status !== null && (
                    <div id="job" style={{ marginTop: "80px" }}>
                      {(postedJobs ?? []).length > 0 && (
                        <div className="talentRecommended__recommendations-container">
                          <div
                            style={{
                              fontWeight: 600,
                              fontSize: "24px",
                              lineHeight: "20.42px",
                              marginBottom: "40px",
                            }}
                          >
                            {" "}
                            Positions Available{" "}
                          </div>
                          <div className="talentRecommended__recommendations-slider">
                            <Slider
                              slidesToShow={Math.min(postedJobs?.length, 3)}
                              slidesToScroll={1}
                              infinite={false}
                              autoplay={true}
                              autoplaySpeed={3000}
                              arrows={false}
                              responsive={[
                                {
                                  breakpoint: 1189, // Width less than or equal to 1024px
                                  settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                    infinite: true,
                                    dots: true,
                                  },
                                },
                                {
                                  breakpoint: 938, // Width less than or equal to 1024px
                                  settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    infinite: true,
                                    dots: true,
                                  },
                                },
                              ]}
                            >
                              {postedJobs?.slice(0, 3).map((job: any) => {
                                return (
                                  <PostedJobCard
                                    job={{
                                      ...job,
                                      employer:
                                        employerProfile?.positions_available
                                          ?.employer_info?.company_name,
                                      style: {
                                        width: "350px",
                                        marginBottom: "20px",
                                      },
                                    }}
                                  />
                                );
                              })}
                            </Slider>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="profile-tail-spin"
          visible={true}
        />
      )}
    </>
  );
};

export default EmployerProfile;
