import React, { CSSProperties, useEffect, useState } from "react";

import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../assets/icons/locationIcon.svg";
import ProfileCover from "../../../assets/images/profileCover.png";
// import { ReactComponent as JobImage } from "../../../assets/images/jobExampleImage.svg";
import type { TabsProps } from "antd";
import { Avatar, Button, message, Rate, Tabs, Tag } from "antd";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { ReactComponent as BagIcon } from "../../../assets/icons/bagIcon.svg";
import SocialMediaIcons from "../../../assets/icons/SocialMediaIcons";
import TechEventImage from "../../../assets/images/largetTechEvent.png";
import { ReactComponent as WriteReview } from "../../../assets/images/writeReview.svg";
import CoachCard from "../../../components/CoachCard/CoachCard";
import DashboardNavbar from "../../../components/DashboardNavbar/DashboardNavbar";
import EditProfileModal from "../Modals/EditProfileModal";

// import { useGoogleLogin } from "@react-oauth/google";

import axios from "axios";
import Cookies from "js-cookie";
import { TailSpin } from "react-loader-spinner";
import { CertificateIcon } from "../../../assets/icons";
import { RecommendedJob, ScheduleMeetingModal } from "../../../components";
import InitialsAvatar from "../../../components/InitialsAvatar/InitialsAvatar";
import RequestForDeleteModal from "../../../components/RequestForDeleteModal/RequestForDeleteModal";
import RequestForLeaveModal from "../../../components/RequestForLeaveModal/RequestForLeaveModal";
import ReviewModal from "../../../components/ReviewModal/ReviewModal";
import { AppDispatch } from "../../../redux/store/store";
import {
  getTalentDashboardRecommended,
  getTalentExplore,
  getTalentProfilePic,
  getTalentPublicProfile,
} from "../../../redux/TalentSlice/TalentSlice";
import api from "../../../utils/api";
import { backdropOverFlowHide } from "../../../utils/backdropOverFlowHide";
import { getAccessToken } from "../../../utils/jwtToken";
import "./Profile.scss";
import Modal from "./Modal";
import PdfViewer from "../../../components/PdfViewer/PdfViewer";
import PdfViewerModal from "../../../components/PdfViewerModal/PdfViewerModal";

type ProfileParams = {
  userType: "talent" | "coach" | "employer" | "partner";
  userId: string;
};

type Props = {
  // typing of data provided by parent
};

type ExperienceObject = {
  company: string;
  end_date: string;
  start_date: string;
  title: string;
};

const TalentProfile: React.FC<Props> = () => {
  const params = useParams();

  const talentId: string | undefined = params.userId;

  const dispatch = useDispatch<AppDispatch>();
  const talentProfile = useSelector((state: any) => state.talent.profileInfo);
  const talentDashboard = useSelector((state: any) => state.talent.dashboard);
  const employerProfile = useSelector(
    (state: any) => state.employer.profileInfo
  );
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const dashboardRecommended = useSelector(
    (state: any) => state.talent.dashboardRecommended
  );
  const [pdfUrl, setPdfUrl] = useState(null);

  const [loading, setLoading] = useState(false);

  const dashboardExplore = useSelector(
    (state: any) => state.talent.dashboardExplore
  );
  const recommendedJobs = dashboardRecommended?.jobs?.jobs;
  const event_data = dashboardExplore?.events_data;

  const [modalActiveTabTalent, setModalActiveTabTalent] = useState("");

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [customStyleForNav, setCustomStyleForNav] = useState({});
  const [openModal, setModalOpen] = useState<boolean>(false);
  const [profileLoading, setProfileLoading] = useState<boolean>(true);
  const [profilePicLoading, setProfilePicLoading] = useState<boolean>(true);
  const [showPdfViewer, setShowPdfViewer] = useState(false);

  const handleButtonClick = () => {
    setShowPdfViewer(true);
  };

  const handleClosePdfModal = () => {
    setShowPdfViewer(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 700);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const defaultStyle: CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "200px",
    marginLeft: "200px",
  };
  const defaultStyle1: CSSProperties = {
    marginLeft: "20px",
    padding: "6px 44px",
    fontSize: "14px",
    fontWeight: 600,
    background: "none",
    color: "#1E1E2F",
    border: "0.5px solid",
  };
  const mediaQueryStyle: CSSProperties = {
    ...defaultStyle,
    flexDirection: "column",
    gap: "2px",
  };
  const mediaQueryStyle1: CSSProperties = {
    ...defaultStyle1,
    marginLeft: "0px",
  };

  const btnStyle = windowWidth <= 520 ? mediaQueryStyle : defaultStyle;
  const btnStyle1 = windowWidth <= 520 ? mediaQueryStyle1 : defaultStyle1;

  useEffect(() => {
    if (isMobileView) {
      setCustomStyleForNav({
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "unset",
        padding: "20px 24px",
      });
    } else {
      setCustomStyleForNav({
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "unset",
        padding: "20px 80px",
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getTalentDashboardRecommended("jobs"));
    dispatch(getTalentExplore("a"));
  }, []);

  useEffect(() => {
    setProfileLoading(true);
    setProfilePicLoading(true);
    if (talentId) {
      dispatch(getTalentPublicProfile(talentId)).then((res) => {
        setProfileLoading(false);
        if (res.payload) {
          // setTalentProfile(res.payload);
        }
      });
      dispatch(getTalentProfilePic(talentId)).then((data) => {
        if (data?.payload?.detail === "Not found.") {
          return;
        }
        setProfilePicLoading(false);
      });
    }
  }, [talentId, openModal]);

  useEffect(() => {
    if (!isPublicView) {
      // dispatch()
    }
  }, []);

  // const handleViewResume = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `${api}/talent/resume/?talent_id=${talentId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${getAccessToken()}`,
  //         },
  //       }
  //     );

  //     const s3Url = response.data.s3_params;

  //     if (s3Url) {
  //       const link = document.createElement("a");
  //       link.href = s3Url; // Use the S3 URL directly

  //       // Ensure the download is recognized as a PDF
  //       link.setAttribute("download", "resume.pdf");
  //       link.setAttribute("type", "resume/pdf");

  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } else {
  //       console.error("The file URL is missing.");
  //       message.error("The file URL is missing.");
  //     }
  //   } catch (error) {
  //     console.error("Download error:", error);
  //     message.error("Resume not found or could not be downloaded.");
  //   } finally {
  //     setLoading(false); // Ensure loading state is reset
  //   }
  // };

  const handleViewResume = async () => {
    setLoading(true);
  
    try {
      const response = await axios.get(
        `${api}/talent/resume/?talent_id=${talentId}`,
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );

      const s3Url = response.data.s3_params;
      console.log("S3 URL: ", s3Url);

      if (s3Url) {
        setPdfUrl(s3Url);
        handleButtonClick();
      } else {
        console.error("The file URL is missing.");
        message.error("The file URL is missing.");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Resume not found.");
    } finally {
      setLoading(false);
    }
  };

  // Commenting it out as this tile might be used later
  function formatDate(dateString: any) {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];

    return { day, month };
  }

  const formattedEvents = event_data?.map((event: any) => {
    const formattedDate = formatDate(event.event_date);
    return { ...event, event_date: formattedDate };
  });

  const formatLocation = (location: any) => {
    const { city = "", state = "", country = "" } = location || {};
    return [city, state, country].filter((part) => part).join(", ");
  };

  const EventTile = ({ eventObj }: any) => {
    const [openModal, setOpenModal] = useState(false);
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <img
            src={eventObj?.event_image ? eventObj?.event_image : TechEventImage}
            alt="tech"
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            padding: "0 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "22px",
            }}
          >
            <div
              style={{
                fontWeight: 700,
                fontSize: "11px",
                lineHeight: "14px",
                color: "#5937CC",
                textAlign: "center",
              }}
            >
              {eventObj.event_date.month}{" "}
            </div>
            <div
              style={{
                fontWeight: 700,
                fontSize: "26px",
                lineHeight: "33px",
                color: "#000000",
              }}
            >
              {eventObj.event_date.day}{" "}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "300px",
              marginLeft: "10px",
              marginTop: "20px",
            }}
          >
            <div
              style={{ fontWeight: 700, fontSize: "16px", lineHeight: "24px" }}
            >
              {eventObj.event_name}
            </div>

            <div
              className="event-desc"
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "21px",
                color: "#6A6A6A",
                marginTop: "10px",
              }}
            >
              {eventObj.event_details}
            </div>
            <div
              onClick={() => setOpenModal(true)}
              style={{
                textDecoration: "none",
                outline: "none",
                flex: 1,
                borderRadius: "24px",
                border: "1px solid var(--Text-Primary)",
                background: "var(--Text-Primary)",
                color: "#f2f4f7",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 700,
                paddingBlock: "10px",
                marginTop: 24,
                cursor: "pointer",
              }}
            >
              View More
            </div>
            {openModal && (
              <Modal eventObj={eventObj} setOpenModal={setOpenModal} />
            )}
          </div>
        </div>
      </div>
    );
  };

  const { userId, userType } = useParams<ProfileParams>();

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  const splitArrayToChunks = (_array: Array<object>, chunk: number) =>
    _array.reduce((all: Array<object>, one, i) => {
      const ch = Math.floor(i / chunk);
      all[ch] = [].concat((all[ch] as any) || [], one as any);
      return all;
    }, []);

  const [_width, setWidth] = useState<number | null>();
  const [selectedTab, setSelectedTab] = useState<string | null>("overview");
  const [reviewModal, setReviewModal] = useState(false);

  useEffect(() => {
    const _clientWidth = document.getElementById("tabs")?.clientWidth;

    if (_clientWidth) setWidth(_clientWidth);
  }, document.getElementById("tabs")?.clientWidth as any);

  const MentorContent: React.FC = () => {
    const mentors = talentProfile?.talent_coaches || [];

    return (
      <div id="MainDiv" style={{ display: "flex", marginBottom: "20px" }}>
        {mentors.length > 0 && (
          <div
            id="Mentor-tiles"
            className="tiles-grid"
            style={{ marginTop: "40px", justifyContent: "space-evenly" }}
          >
            {mentors.map((mentor: any) => (
              <CoachCard coach={mentor} />
            ))}
          </div>
        )}
        {mentors.length === 0 && (
          <div
            style={{
              fontWeight: "400",
              fontSize: "15px",
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
              marginInline: "auto",
            }}
          >
            {talentDashboard?.subscription_status !== "trailblazer" ? (
              <span>
                You haven't chosen a coach. <Link to={"/billing"}>Upgrade</Link>{" "}
                to have one
              </span>
            ) : (
              <span>
                You haven’t chosen a coach yet. Please go to{" "}
                <Link to={"/view-all/coach"}>coaches</Link> page and have
                yourself a coach.
              </span>
            )}
          </div>
        )}
      </div>
    );
  };

  const ReviewsContent: React.FC = () => {
    return (
      <div id="MainDiv" style={{ display: "flex", marginBottom: "20px" }}>
        <div
          id="Mentor-tiles"
          className="reviews-section"
          // style={{ marginTop: "40px", justifyContent: "space-evenly" }}
        >
          {isPublicView && Cookies.get("role") !== "employer" && (
            <WriteReviewTile />
          )}
          {reviewModal && (
            <ReviewModal
              handleReviewModal={handleReviewModal}
              coachId={talentProfile?.id}
              talentId={talentDashboard?.profile?.id}
            />
          )}
          {talentProfile?.reviews?.length > 0 &&
            talentProfile?.reviews?.map((review: any) => (
              <ReviewTile reviewObj={review} />
            ))}
        </div>
        {talentProfile?.reviews?.length === 0 && (
          <div
            style={{
              fontWeight: "400",
              fontSize: "15px",
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
              marginInline: "auto",
            }}
          >
            <span>No reviews yet!</span>
          </div>
        )}
      </div>
    );
  };

  const OverViewContent: React.FC = () => {
    // const skillsList = new Array(7).fill("Skill");
    const skillsList = (talentProfile?.talent_skills ?? []).map(
      (skl: any) => skl.skill
    );
    const industriesList = (talentProfile?.preferences?.industries ?? []).map(
      (preference: any) => preference
    );
    // const industries = new Array(5).fill("Industry");

    const certifications = splitArrayToChunks(
      talentProfile?.talent_certifications || [],
      2
    ) as Array<Array<{ institute: string; certification_name: string }>>;

    const experiences = splitArrayToChunks(
      talentProfile?.talent_job_experiences || [],
      2
    ) as Array<Array<ExperienceObject>>;

    // const education = splitArrayToChunks(
    //   [{ title: "XYZ University, LA", desc: "BS in Graphic Design 2015-2019" }],
    //   2
    // ) as Array<Array<DetailObject>>;
    const education = splitArrayToChunks(
      talentProfile?.talent_education || [],
      2
    );
    // ({
    //   id: 1,
    //   school: "Miller, King and Fields",
    //   degree: "PhD",
    //   field: "Investment banker, operational",
    //   start_date: "1980-01-18",
    //   end_date: "1990-03-25",
    //   description:
    //     "Clear fight feel tree chance short money. Nation baby take person challenge along. Upon hold by traditional. Whom build bed southern support.",
    //   talent: "8a4bc605-b914-40d9-8c8e-2068eb73c59a",
    // },
    // {
    //   id: 2,
    //   school: "Armstrong, Perry and Hood",
    //   degree: "Masters",
    //   field: "Surveyor, land/geomatics",
    //   start_date: "1985-04-24",
    //   end_date: "1996-04-02",
    //   description:
    //     "Create decision whose growth walk across cost. Theory keep likely thus. Course future rock indicate technology son travel. Toward drug small very.",
    //   talent: "8a4bc605-b914-40d9-8c8e-2068eb73c59a",
    // })

    useEffect(() => {
      if (openModal) {
        backdropOverFlowHide();
      }
      return () => {
        document.body.style.overflow = "";
      };
    }, []);

    return (
      <div id="MainDiv" style={{ display: "flex" }}>
        <div
          id="MainDivFirstHalf"
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "700px",
            minWidth: "400px",
            marginTop: "12px",
          }}
        >
          <div id="About">
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              About{" "}
            </div>

            <p
              style={{
                marginTop: "16px",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {talentProfile?.bio || (
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span>
                    About section is empty. {"   "}
                    {!isPublicView && (
                      <>
                        <span
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (!isPublicView) {
                              setModalOpen(true);
                              setModalActiveTabTalent("personal-info");
                            }
                          }}
                        >
                          {" "}
                          Click here
                        </span>
                        {"   "} to add through profile edit.
                      </>
                    )}
                  </span>
                </div>
              )}
            </p>
          </div>

          <hr className="horizontal-line-break" />

          <div id="Open to" style={{ marginTop: "40px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Open to{" "}
            </div>

            {talentProfile?.preferences?.role.length ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <ol
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "50px",
                      flexWrap: "wrap",
                      padding: "0 0 0 30px",
                    }}
                  >
                    {talentProfile?.preferences?.role.map((elem: string) => {
                      return <li>{elem}</li>;
                    })}
                  </ol>
                </div>
              </div>
            ) : (
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "center",
                }}
              >
                <span>
                  Role type is empty. {"   "}
                  {!isPublicView && (
                    <>
                      <span
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => !isPublicView && setModalOpen(true)}
                      >
                        {" "}
                        Click here
                      </span>
                      {"   "} to add through profile edit.
                    </>
                  )}
                </span>
              </div>
            )}

            {/*<div style={{ display: "flex", flexDirection: "column" }}>*/}
            {/*  <div style={{ display: "flex", marginTop: "30px" }}>*/}
            {/*    {openTo.map((_open: any, i: number) => (*/}
            {/*      <div*/}
            {/*        style={{*/}
            {/*          fontWeight: 600,*/}
            {/*          fontSize: "16px",*/}
            {/*          lineHeight: "20.42px",*/}
            {/*          minWidth: i === 0 ? "268px" : "unset",*/}
            {/*          maxWidth: i === 0 ? "268px" : "unset",*/}
            {/*          marginLeft: i > 0 ? "70px" : "unset",*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <div style={{ display: "flex" }}>*/}
            {/*          <BagIcon />*/}
            {/*          <div style={{ marginLeft: "10px" }}>{_open.title}</div>*/}
            {/*        </div>*/}

            {/*        <p style={{ fontSize: "14px", fontWeight: "300" }}>*/}
            {/*          {" "}*/}
            {/*          {_open.desc}*/}
            {/*        </p>*/}
            {/*      </div>*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          <hr className="horizontal-line-break" />

          <div id="Open to" style={{ marginTop: "40px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Work preference{" "}
            </div>

            {talentProfile?.preferences?.work_mode.length > 0 ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <ol
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "50px",
                      flexWrap: "wrap",
                      padding: "0 0 0 30px",
                    }}
                  >
                    {talentProfile?.preferences?.work_mode.map(
                      (elem: string) => {
                        return <li>{elem}</li>;
                      }
                    )}
                  </ol>
                </div>
              </div>
            ) : (
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <span>
                  Work preference is empty. {"   "}
                  {!isPublicView && (
                    <>
                      <span
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => !isPublicView && setModalOpen(true)}
                      >
                        {" "}
                        Click here
                      </span>
                      {"   "} to add through profile edit.
                    </>
                  )}
                </span>
              </div>
            )}
          </div>

          <hr className="horizontal-line-break" />

          <div id="Experience" style={{ marginTop: "40px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Experience{" "}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {experiences?.length > 0 ? (
                experiences.map((_exp) => (
                  <div>
                    {_exp.map((_ex, i) => {
                      const isExperienceComplete =
                        _ex.company &&
                        _ex.title &&
                        (_ex.start_date || _ex.end_date);

                      if (!isExperienceComplete) {
                        return (
                          <div
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                            }}
                          >
                            Experience Level is empty
                          </div>
                        );
                      }

                      const startDateObj = _ex.start_date
                        ? new Date(_ex.start_date)
                        : null;
                      const startDateMonth = startDateObj?.toLocaleString(
                        "default",
                        { month: "long" }
                      );
                      const startDateYear = startDateObj?.getFullYear();

                      const endDateObj = _ex.end_date
                        ? new Date(_ex.end_date)
                        : null;
                      const endDateMonth = endDateObj?.toLocaleString(
                        "default",
                        { month: "long" }
                      );
                      const endDateYear = endDateObj?.getFullYear();

                      return (
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "20.42px",
                            minWidth: i === 0 ? "268px" : "unset",
                            maxWidth: i === 0 ? "268px" : "unset",
                            marginTop: "20px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <BagIcon />
                            <div style={{ marginLeft: "10px" }}>
                              {_ex.company}
                            </div>
                          </div>

                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "300",
                            }}
                          >
                            {_ex.title + " "}
                            {startDateObj
                              ? `(${
                                  startDateMonth +
                                  " " +
                                  startDateYear?.toString() +
                                  " - " +
                                  (endDateObj
                                    ? endDateMonth + " " + endDateYear
                                    : "Present")
                                })`
                              : ""}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ))
              ) : (
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <span>
                    Experience is empty. {"   "}
                    {!isPublicView && (
                      <span
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!isPublicView) {
                            setModalOpen(true);
                            setModalActiveTabTalent("exp-edu");
                          }
                        }}
                      >
                        {" "}
                        Click here
                      </span>
                    )}
                    {!isPublicView ? " to add through profile edit." : ""}
                  </span>
                </div>
              )}
            </div>
          </div>

          <hr className="horizontal-line-break" />

          <div
            id="Education"
            style={{ marginTop: "40px", marginBottom: "40px" }}
          >
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Education{" "}
            </div>
            {education?.length === 0 ? (
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <span>
                  Education details is empty. {"   "}
                  {!isPublicView && (
                    <span
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!isPublicView) {
                          setModalOpen(true);
                          setModalActiveTabTalent("exp-edu");
                        }
                      }}
                    >
                      {" "}
                      Click here
                    </span>
                  )}
                  {!isPublicView ? " to add through profile edit." : ""}
                </span>
              </div>
            ) : (
              <div
                className="education-list"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {education.map((_edu: any) => (
                  <div>
                    {_edu.map((_edu: any, i: any) => {
                      // Check if essential fields are present
                      const isEducationComplete =
                        _edu.school &&
                        _edu.degree &&
                        (_edu.start_date || _edu.end_date);

                      if (!isEducationComplete) {
                        return (
                          <div
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                            }}
                          >
                            Education Level is empty. {"   "}
                          </div>
                        );
                      }

                      const startDateObj = _edu.start_date
                        ? new Date(_edu.start_date)
                        : null;
                      const startDateMonth = startDateObj?.toLocaleString(
                        "default",
                        { month: "long" }
                      );
                      const startDateYear = startDateObj?.getFullYear();

                      const endDateObj = _edu.end_date
                        ? new Date(_edu.end_date)
                        : null;
                      const endDateMonth = endDateObj?.toLocaleString(
                        "default",
                        { month: "long" }
                      );
                      const endDateYear = endDateObj?.getFullYear();

                      return (
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "20.42px",
                            minWidth: i === 0 ? "268px" : "unset",
                            maxWidth: i === 0 ? "268px" : "unset",
                            marginTop: "20px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <BagIcon />
                            <div style={{ marginLeft: "10px" }}>
                              {_edu.school}
                            </div>
                          </div>
                          <p style={{ fontSize: "14px", fontWeight: "300" }}>
                            {_edu.degree + " "}
                            {startDateObj
                              ? `(${
                                  startDateMonth +
                                  " " +
                                  startDateYear?.toString() +
                                  " - " +
                                  (endDateObj
                                    ? endDateMonth + " " + endDateYear
                                    : "Present")
                                })`
                              : ""}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* LINE */}
        <div className="vertical-line" />

        {/*  2ND RIGHT HALF SECTION*/}

        <div
          id="MainDivSecondHalf"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "40px",
            marginLeft: "42px",
          }}
        >
          <div id="Skills">
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Skills{" "}
            </div>

            {/* <p style={{ marginTop: "16px", fontWeight: 400, fontSize: "16px", lineHeight: "20.42px" }}>
       Hey all :) Happy to be here to seek advice from the best of the minds :) Being a singer, artist, guitar player, athlete, martial arts and voice over artist by hobby, as a UX Researcher I am passionate about blending business goals with user. My research experience lies in Fashion, E- commerce, NBFC and Automotive domain, worked in tools such as usertesting.com, Hotjar, Miro, Survey Monkey, MS Office, Illustrator and Figma.
       </p>  */}
            <div
              id="skills"
              style={{
                marginTop: "10px",
                display: "flex",
                marginLeft: "-10px",
              }}
            >
              {skillsList?.length ? (
                <div>
                  {skillsList.map((skill: string) => (
                    <Tag
                      color="#BDAFEB"
                      style={{
                        width: "fit-content",
                        borderRadius: "12px",
                        color: "#344054",
                        padding: "4px 12px 4px 12px",
                        fontSize: "14px",
                        marginTop: "10px",
                        paddingTop: "1px",
                        paddingBottom: "2px",
                        marginLeft: "10px",
                      }}
                    >
                      {skill}
                    </Tag>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "10px",
                  }}
                >
                  <span>
                    Skills are empty. {"   "}
                    <span
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!isPublicView) {
                          setModalOpen(true);
                          setModalActiveTabTalent("exp-edu");
                        }
                      }}
                    >
                      {" "}
                      Click here
                    </span>
                    {"   "} to add through profile edit.
                  </span>
                </div>
              )}
            </div>
          </div>

          <hr
            className="horizontal-line-break"
            style={{ width: _width ? _width / 2 - 2 + "px" : "unset" }}
          />

          <div id="Industries" style={{ marginTop: "40px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Industries{" "}
            </div>

            {/* <p style={{ marginTop: "16px", fontWeight: 400, fontSize: "16px", lineHeight: "20.42px" }}>
       Hey all :) Happy to be here to seek advice from the best of the minds :) Being a singer, artist, guitar player, athlete, martial arts and voice over artist by hobby, as a UX Researcher I am passionate about blending business goals with user. My research experience lies in Fashion, E- commerce, NBFC and Automotive domain, worked in tools such as usertesting.com, Hotjar, Miro, Survey Monkey, MS Office, Illustrator and Figma.
       </p>  */}
            <div
              id="Industries"
              style={{
                marginTop: "10px",
                display: "flex",
                marginLeft: "-10px",
              }}
            >
              {industriesList?.length ? (
                <div>
                  {industriesList.map((preference: string) => (
                    <Tag
                      color="#BDAFEB"
                      style={{
                        width: "fit-content",
                        borderRadius: "12px",
                        color: "#344054",
                        padding: "4px 12px 4px 12px",
                        fontSize: "14px",
                        marginTop: "10px",
                        paddingTop: "1px",
                        paddingBottom: "2px",
                        marginLeft: "10px",
                      }}
                    >
                      {preference}
                    </Tag>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "10px",
                  }}
                >
                  <span>
                    Industry preferences are empty. {"   "}
                    {!isPublicView && (
                      <span
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!isPublicView) {
                            setModalOpen(true);
                            setModalActiveTabTalent("exp-edu");
                          }
                        }}
                      >
                        {" "}
                        Click here
                      </span>
                    )}
                    {!isPublicView ? " to add through profile edit." : ""}
                  </span>
                </div>
              )}
            </div>
          </div>

          <hr
            className="horizontal-line-break"
            style={{ width: _width ? _width / 2 - 2 + "px" : "unset" }}
          />

          {/*             <div id="Experties" style={{marginTop: "40px"}}>*/}
          {/*                 <div*/}
          {/*                     style={{*/}
          {/*                         fontWeight: 600,*/}
          {/*                         fontSize: "16px",*/}
          {/*                         lineHeight: "20.42px",*/}
          {/*                     }}*/}
          {/*                 >*/}
          {/*                     {" "}*/}
          {/*                     Experties{" "}*/}
          {/*                 </div>*/}

          {/*                 /!* <p style={{ marginTop: "16px", fontWeight: 400, fontSize: "16px", lineHeight: "20.42px" }}>*/}
          {/*Hey all :) Happy to be here to seek advice from the best of the minds :) Being a singer, artist, guitar player, athlete, martial arts and voice over artist by hobby, as a UX Researcher I am passionate about blending business goals with user. My research experience lies in Fashion, E- commerce, NBFC and Automotive domain, worked in tools such as usertesting.com, Hotjar, Miro, Survey Monkey, MS Office, Illustrator and Figma.*/}
          {/*</p>  *!/*/}
          {/*                 <div*/}
          {/*                     style={{*/}
          {/*                         marginTop: "10px",*/}
          {/*                         display: "flex",*/}
          {/*                         marginLeft: "-10px",*/}
          {/*                     }}*/}
          {/*                 >*/}
          {/*                     <div>*/}
          {/*                         {experties.map((exp, index) => (*/}
          {/*                             <Tag*/}
          {/*                                 color="#FDCEB5"*/}
          {/*                                 style={{*/}
          {/*                                     width: "fit-content",*/}
          {/*                                     borderRadius: "16px",*/}
          {/*                                     color: "#344054",*/}
          {/*                                     padding: "4px 12px 4px 12px",*/}
          {/*                                     fontSize: "14px",*/}
          {/*                                     marginTop: "10px",*/}
          {/*                                     paddingTop: "1px",*/}
          {/*                                     paddingBottom: "2px",*/}
          {/*                                     marginLeft: "10px",*/}
          {/*                                 }}*/}
          {/*                             >*/}
          {/*                                 {exp}*/}
          {/*                             </Tag>*/}
          {/*                         ))}*/}
          {/*                     </div>*/}
          {/*                 </div>*/}
          {/*             </div>*/}

          {/*<hr className="horizontal-line-break"/>*/}

          <div id="Certifications" style={{ marginTop: "40px" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20.42px",
              }}
            >
              {" "}
              Certifications{" "}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "80px",
              }}
            >
              {!certifications?.length ? (
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "10px",
                  }}
                >
                  <span>
                    Certificates are empty. {"   "}
                    {!isPublicView && (
                      <span
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!isPublicView) {
                            setModalOpen(true);
                            setModalActiveTabTalent("exp-edu");
                          }
                        }}
                      >
                        {" "}
                        Click here
                      </span>
                    )}
                    {!isPublicView ? " to add through profile edit." : ""}
                  </span>
                </div>
              ) : (
                certifications.map((_cert) => (
                  <div>
                    {_cert.map((_cer, i) => (
                      <div
                        style={{
                          fontWeight: 600,
                          fontSize: "16px",
                          lineHeight: "20.42px",
                          minWidth: i === 0 ? "268px" : "unset",
                          maxWidth: i === 0 ? "268px" : "unset",
                          marginTop: "20px",
                          // marginLeft: i > 0 ? "70px" : "unset",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <CertificateIcon />
                          <div style={{ marginLeft: "10px" }}>
                            {_cer.institute}
                          </div>
                        </div>

                        <p style={{ fontSize: "14px", fontWeight: "300" }}>
                          {" "}
                          {_cer.certification_name}
                        </p>
                      </div>
                    ))}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleReviewModal = () => {
    setReviewModal((prevState) => !prevState);
  };

  const WriteReviewTile = () => {
    return (
      <div
        className="job-tile"
        style={{
          background: "#FBAD84",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "334px",
          marginTop: "40px",
        }}
      >
        {/* Review Paragraph */}

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginTop: "30px",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "24px",
              color: "#1E1E2F",
            }}
          >
            {"Write a Review"}
          </div>

          <div
            style={{
              marginTop: "10px",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "20px",
              color: "#344054",
              width: "320px",
            }}
          >
            {
              "Share your experience and help others uncover opportunities, connections, and growth. Your feedback empowers our tech community."
            }
          </div>

          <div style={{ display: "flex", marginTop: "12px" }}>
            <Button
              type="primary"
              shape="round"
              size={"large"}
              style={{
                padding: "6px 44px",
                fontSize: "14px",
                fontWeight: 700,
                background: "none",
                color: "#1E1E2F",
                border: "0.5px solid",
              }}
              onClick={handleReviewModal}
            >
              Write a Review
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "-10px",
            }}
          >
            <WriteReview />
          </div>
        </div>
      </div>
    );
  };

  const ReviewTile = ({ reviewObj }: any) => {
    return (
      <div
        className="job-tile"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "334px",
          marginTop: "40px",
        }}
      >
        {/* Review Paragraph */}
        <div
          className="review-paragraph"
          style={{
            marginTop: "30px",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "30px",
            color: "#111111",
            width: "320px",
          }}
        >
          {reviewObj.review}
        </div>

        {/* Review Rating */}
        <div
          className="review-rating"
          style={{
            marginTop: "30px",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "30px",
            color: "#111111",
            width: "320px",
          }}
        >
          <Rate disabled value={reviewObj.rating} />
        </div>

        <div
          className="review-avatar-sec"
          style={{
            display: "flex",
            width: "100%",
            marginTop: "40px",
            justifyContent: "space-around",
            alignItems: "center",
            // marginLeft: "30px",
          }}
        >
          {reviewObj.talent?.image ? (
            <Avatar
              style={{ width: "70px", height: "70px", marginTop: "30px" }}
              src={reviewObj.reviewObj.talent?.image}
            />
          ) : (
            <InitialsAvatar
              name={reviewObj.talent?.name}
              customStyles={{ width: "70px", height: "70px" }}
            />
          )}

          <div
            style={{
              display: "flex",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "20px",
              color: "#344054",
              // marginTop: "20px",
              alignItems: "baseline",
              flexDirection: "column",
              marginRight: "50px",
            }}
          >
            {reviewObj.talent?.name || ""}
            <div
              style={{
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "20px",
                color: "#6F6C90",
                marginTop: "4px",
                alignItems: "center",
              }}
            >
              {" "}
              {reviewObj.talent?.profession}{" "}
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#6F6C90",
                marginTop: "4px",
                alignItems: "center",
              }}
            >
              {" "}
              {reviewObj.talent_latest_career?.position}{" "}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const isPublicView = talentId !== talentDashboard?.profile?.id;

  const items: TabsProps["items"] = [
    {
      key: "overview",
      label: "Overview",
      children: <OverViewContent />,
      style: { fontFamily: "Space Grotesk" },
    },

    ...(profileInfo?.role !== "employer" && !talentProfile?.is_coach
      ? [
          {
            key: "mentors",
            label: isPublicView ? "Coaches" : "My Coaches",
            children: <MentorContent />,
            style: { fontFamily: "Space Grotesk" },
          },
        ]
      : []),
    ...(talentProfile?.is_coach
      ? [
          {
            key: "reviews",
            label: "Reviews",
            children: <ReviewsContent />,
            style: { fontFamily: "Space Grotesk" },
          },
        ]
      : []),
  ];

  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteAccount = () => {
    setModalOpen(false);
    setOpenLeaveModal(true);
  };
  const handleContinueDelete = () => {
    setOpenDeleteModal(true);
    setOpenLeaveModal(false);
  };

  const handleCancelDelete = () => {
    setOpenLeaveModal(false);
    setOpenDeleteModal(false);
  };
  useEffect(() => {
    if (openLeaveModal || openDeleteModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [openLeaveModal, openDeleteModal]);
  const validUrl =
    pdfUrl ||
    "https://s3.us-east-2.amazonaws.com/prod.tribaja.co/m9NPS7C2Q9ebfe6GgX-5TNctGwI.doc?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAZOUJ255V5ZW76HE7%2F20240822%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240822T144825Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=adcdb2bba98b921abdd59891a2cc27a50731a0f90b6f6a5cdfe6aee8ed38a950";

  return (
    <>
      {openDeleteModal && (
        <RequestForDeleteModal handleCancelDelete={handleCancelDelete} />
      )}
      {openLeaveModal && (
        <RequestForLeaveModal
          handleCancelDelete={handleCancelDelete}
          handleContinueDelete={handleContinueDelete}
        />
      )}
      {openModal && (
        <EditProfileModal
          handleDeleteAccount={handleDeleteAccount}
          formModalType="TALENT_FORM"
          onModalClose={() => setModalOpen(false)}
          modalActiveTabTalent={modalActiveTabTalent}
        />
      )}
      {((talentProfile && talentDashboard) || isPublicView) && (
        <DashboardNavbar
          addLogo={true}
          // talentProfileLogo={true}
          customStyles={customStyleForNav}
        />
      )}
      {talentProfile?.id && !profileLoading ? (
        <div className="talent-profile" style={{ marginBottom: "20px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div id="CoverImageDiv">
              <img
                className="profile-banner-img"
                style={{ width: "100%" }}
                src={ProfileCover}
                alt="ProfileCover"
              />
            </div>

            <div id="ProfileTopSectionDiv" style={btnStyle}>
              <div className="user-avatar" style={{ display: "flex" }}>
                <div
                  className="profile-image-avatar-div"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    alignItems: "center",
                    marginTop: "-70px",
                  }}
                >
                  {(talentProfile?.s3_params || employerProfile?.s3_params) &&
                  !profilePicLoading ? (
                    <Avatar
                      className="profile-image-avatar"
                      style={{
                        width: "200px",
                        height: "205px",
                        border: "3px solid #FBAD84",
                      }}
                      src={
                        isPublicView
                          ? talentProfile?.s3_params
                          : talentDashboard?.profile_image || null
                      }
                      icon={<UserOutlined />}
                      size={200}
                    />
                  ) : (
                    <div className="box-avatar">
                      <InitialsAvatar
                        name={talentProfile?.full_name}
                        customStyles={{ fontSize: "38px" }}
                      />
                    </div>
                  )}
                
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "baseline",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      marginLeft: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <p className="font-class talent-profile-name">
                      {talentProfile?.full_name}
                    </p>
                    <p
                      style={{ marginLeft: "10px", marginTop: "20px" }}
                      className="font-class-gender"
                    >
                      {" "}
                      {talentProfile?.demographics?.[0] || "---"}{" "}
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",

                      whiteSpace: "nowrap",
                    }}
                  >
                    <LocationIcon />{" "}
                    <p
                      style={{ marginLeft: "10px" }}
                      className="location-state"
                    >
                      {/*  talent_location is an array coming from backend, showing 0 */}

                      {talentProfile?.talent_location
                        ? `${talentProfile?.talent_location?.city}, ${talentProfile?.talent_location?.state}`
                        : "------"}
                    </p>
                  </div>

                  <div className="job-label" style={{ marginTop: "10px" }}>
                    <Tag
                      color="#FEEDCC"
                      style={{
                        borderRadius: "12px",
                        color: "#1E1E2F",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      {" "}
                      {talentProfile?.profession
                        ? talentProfile?.profession
                        : "Profession not selected yet"}{" "}
                    </Tag>
                  </div>
                </div>
              </div>

              <div
                id="SocialMediaAndButtonDiv"
                style={{ display: "flex", marginTop: "40px" }}
              >
                {selectedTab === "overview" && (
                  <div
                    id="SocialMediaIcons"
                    style={{
                      display: "flex",
                      marginRight: "20px",
                      marginTop: "4px",
                    }}
                  >
                    <a
                      href={
                        talentProfile?.social_links?.github.includes("https://")
                          ? talentProfile?.social_links?.github
                          : `https://${talentProfile?.social_links?.github}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {talentProfile?.social_links?.github && (
                        <SocialMediaIcons.githubIcon
                          style={{ marginRight: "10px" }}
                        />
                      )}
                    </a>
                    <a
                      href={
                        talentProfile?.social_links?.twitter.includes(
                          "https://"
                        )
                          ? talentProfile?.social_links?.twitter
                          : `https://${talentProfile?.social_links?.twitter}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {talentProfile?.social_links?.twitter && (
                        <SocialMediaIcons.twitter
                          style={{ marginRight: "10px" }}
                        />
                      )}
                    </a>
                    <a
                      href={
                        talentProfile?.social_links?.linkedin.includes(
                          "https://"
                        )
                          ? talentProfile?.social_links?.linkedin
                          : `https://${talentProfile?.social_links?.linkedin}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {talentProfile?.social_links?.linkedin && (
                        <SocialMediaIcons.linkedin
                          style={{ marginRight: "10px" }}
                        />
                      )}
                    </a>
                    <a
                      href={
                        talentProfile?.social_links?.website.includes(
                          "https://"
                        )
                          ? talentProfile?.social_links?.website
                          : `https://${talentProfile?.social_links?.website}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {talentProfile?.social_links?.website && (
                        <SocialMediaIcons.website
                          style={{ marginRight: "10px" }}
                        />
                      )}
                    </a>
                  </div>
                )}

                <div>
                  {showPdfViewer && (
                    <PdfViewerModal
                      s3Url={pdfUrl}
                      handleToggleModal={handleClosePdfModal}
                    />
                  )}
                </div>

                <div id="ButtonSection">
                  <Button
                    type="primary"
                    shape="round"
                    size={"large"}
                    style={btnStyle1}
                    onClick={() => handleViewResume()}
                  >
                    {!loading ? (
                      "View Resume"
                    ) : (
                      <TailSpin
                        height="20"
                        width="80"
                        color="#FBAD84"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{
                          justifySelf: "center",
                          alignSelf: "center",
                        }}
                        wrapperClass="tail-spin"
                        visible={true}
                      />
                    )}
                  </Button>

                  {!isPublicView && (
                    <Button
                      type="primary"
                      shape="round"
                      size={"large"}
                      style={btnStyle1}
                      onClick={(event) => {
                        event.preventDefault();

                        setModalOpen(true);
                        setModalActiveTabTalent("personal-info");
                      }}
                    >
                      Edit Profile
                    </Button>
                  )}
                </div>

                
              </div>
            </div>

           
            {/* Tabs Section */}

            <div
              className="tabs_container"
              style={{
                marginTop: "100px",
                marginLeft: "200px",
                marginRight: "200px",
              }}
            >
              <Tabs
                id="tabs"
                defaultActiveKey="overview"
                items={items}
                onChange={onChange}
              />
              {selectedTab === "overview" && (
                <hr
                  className="horizontal-line-break"
                  style={{ marginTop: "unset" }}
                />
              )}

              {/* {selectedTab === "reviews" && Cookies.get('role') === "coach" && (

              )} */}

              {/* Opportunities Section  */}

              {selectedTab === "overview" && !isPublicView && (
                <div
                  id="opportunities"
                  style={{ marginTop: "100px", marginBottom: "20px" }}
                >
                  <div
                    className="opportunities-heading"
                    style={{
                      fontWeight: 700,
                      fontSize: "32px",
                      lineHeight: "20.42px",
                    }}
                  >
                    {" "}
                    Opportunities and Resources that Match My Profile{" "}
                  </div>

                  {/* Jobs */}

                  {(recommendedJobs ?? []).length > 0 && (
                    <div className="talentRecommended__recommendations-container">
                      <div className="see-all-title">
                        <Link to="/view-all/job">See all</Link>
                      </div>
                      <div className="talentRecommended__recommendations-slider">
                        {formattedEvents && (
                          <Slider
                            dots={true}
                            slidesToShow={Math.min(recommendedJobs?.length, 3)}
                            slidesToScroll={3}
                            autoplay={true}
                            autoplaySpeed={3000}
                            arrows={false}
                            responsive={[
                              {
                                breakpoint: 1189, // Width less than or equal to 1024px
                                settings: {
                                  slidesToShow: 2,
                                  slidesToScroll: 2,
                                  infinite: true,
                                  dots: true,
                                },
                              },
                              {
                                breakpoint: 938, // Width less than or equal to 1024px
                                settings: {
                                  slidesToShow: 1,
                                  slidesToScroll: 1,
                                  infinite: true,
                                  dots: true,
                                },
                              },
                            ]}
                          >
                            {recommendedJobs?.slice(0, 10).map((job: any) => {
                              return (
                                <RecommendedJob
                                  job={{
                                    ...job,
                                    style: { marginBottom: "40px" },
                                  }}
                                />
                              );
                            })}
                          </Slider>
                        )}
                      </div>
                    </div>
                  )}

                  {/* <div id="job" style={{marginTop: "50px"}}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontWeight: 600,
                                                    fontSize: "24px",
                                                    lineHeight: "20.42px",
                                                    marginBottom: "40px",
                                                }}
                                            >
                                                {" "}
                                                Jobs{" "}
                                            </div>
                                            <a
                                                href={"/"}
                                                style={{
                                                    fontWeight: 500,
                                                    fontSize: "16px",
                                                    lineHeight: "20.42px",
                                                    marginBottom: "40px",
                                                    color: "#5937CC",
                                                    textDecoration: "underline",
                                                }}
                                            >
                                                {" "}
                                                See All{" "}
                                            </a>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {jobs.map((job) => (
                                                <JobTile jobObj={job}/>
                                            ))}
                                        </div>
                                    </div> */}

                  {/* Events */}
                  <div id="events" style={{ marginTop: "110px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 600,
                          fontSize: "24px",
                          lineHeight: "20.42px",
                          marginBottom: "40px",
                        }}
                      >
                        {" "}
                        Events
                      </div>
                      {/* <Link to={'/view-all/resource'}>See All</Link> */}
                    </div>

                    <div className="talentRecommended__recommendations-slider">
                      <Slider
                        dots={true}
                        slidesToShow={
                          formattedEvents?.length >= 3
                            ? 3
                            : formattedEvents?.length
                        }
                        slidesToScroll={1}
                        autoplay={true}
                        autoplaySpeed={3000}
                        arrows={false}
                        responsive={[
                          {
                            breakpoint: 1350,
                            settings: {
                              slidesToShow:
                                formattedEvents?.length >= 2
                                  ? 2
                                  : formattedEvents?.length,
                              slidesToScroll: 1,
                              dots: true,
                            },
                          },
                          {
                            breakpoint: 938, // Width less than or equal to 1024px
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                              dots: true,
                            },
                          },
                        ]}
                      >
                        {formattedEvents &&
                          formattedEvents?.length >= 1 &&
                          formattedEvents.map((eve: any) => {
                            return <EventTile key={eve.id} eventObj={eve} />;
                          })}
                      </Slider>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="profile-tail-spin"
          visible={true}
        />
      )}
    </>
  );
};

export default TalentProfile;
