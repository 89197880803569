import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";

import "./ViewResource.scss";
import {TailSpin} from "react-loader-spinner";
import {useDispatch} from "react-redux";
import {getPartnerResource} from "../../redux/PartnerSlice/PartnerSlice";
import {AppDispatch} from "../../redux/store/store";
import {AnimatePresence, motion} from "framer-motion";
import {getAdminResource} from "../../redux/AdminSlice/AdminSlice";

const ViewResource = () => {
    const [loading, setLoading] = useState(true);

    const {resourceId} = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [resource, setResource] = useState<any>(null);

    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);
    const [customStyleForNav, setCustomStyleForNav] = useState({});

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 700);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobileView) {
            setCustomStyleForNav({
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "unset",
                padding: "20px 24px",
            });
        } else {
            setCustomStyleForNav({
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "unset",
                padding: "20px 80px",
            });
        }
    }, []);

    useEffect(() => {
        if (resourceId) {
            setLoading(true);
            if (window.location.href.includes("admin")) {
                dispatch(getAdminResource(resourceId)).then((data) => {
                    if (data.payload.program_title) {
                        setResource(data.payload);
                        setLoading(false);
                    } else {
                        navigate("/404");
                    }
                });
            } else {
                dispatch(getPartnerResource(resourceId)).then((data) => {
                    if (data.payload.program_title) {
                        setResource(data.payload);
                        setLoading(false);
                    } else {
                        navigate("/404");
                    }
                });
            }
        }
    }, [resourceId]);

    return (
        <>
            {!loading ? (
                <>
                    {window.location.pathname.includes("admin") ? (
                        ""
                    ) : (
                        <DashboardNavbar
                            addLogo={true}
                            customStyles={customStyleForNav}
                        />
                    )}
                    <AnimatePresence>
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            className="viewResource"
                        >
                            <div className="viewResource__container">
                                <div className="viewResource__container-heading">
                                    <h2>{resource?.program_title}</h2>
                                    <p>by {resource?.company ?? "Unknown"}</p>
                                    <Link to={resource.program_link}>
                                        Go to resource
                                    </Link>
                                </div>
                                <div className="viewResource__box">
                                    <div className="col-1">
                                        <div className="viewResource__box-description">
                                            Description
                                            <p>{resource?.program_description ?? "No program description mentioned."}</p>
                                        </div>
                                        <div className="viewResource__box-diversity">
                                            We show our commitment to diversity through
                                            <p>{resource?.diversity_commitment ?? "No disversity commitment mentioned."}</p>
                                        </div>
                                        <div className="viewResource__box-type">
                                            Type of Resource
                                            <div className="tag-box">
                                                {resource.resource_type.length ? resource?.resource_type?.map((res: string) => (
                                                    <div className="tag">{res}</div>
                                                )) : <span style={{
                                                    fontWeight: "400"
                                                }}>No resource types mentioned.</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="viewResource__box-perks">
                                            Perks
                                            <p>{resource?.perks ?? "No perks mentioned."}</p>
                                        </div>
                                        <div className="viewResource__box-cost">
                                            Cost
                                            <p>${resource?.cost} per seat</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                </>
            ) : (
                <div className="resource-loader-container">
                    <TailSpin
                        height="160"
                        width="160"
                        color="#FBAD84"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{
                            justifySelf: "center",
                            alignSelf: "center",
                        }}
                        wrapperClass="tail-spin"
                        visible={true}
                    />
                </div>

            )}
        </>
    );
};

export default ViewResource;
