import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

import { Select, message } from "antd";

import { TailSpin } from "react-loader-spinner";

import "./PostJobPage.scss";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";

import { AppDispatch } from "../../redux/store/store";

import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import FormButton from "../../components/FormButton/FormButton";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import { getJob, updateJob } from "../../redux/EmployerSlice/EmployerSlice";
import { stripHtml } from "../../utils/stripHTML";

const Option = Select.Option;

const EmployerDashboard: FC = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState("");

  // Dispatch
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // Selectors
  const profileInfo = useSelector((state: any) => state.profile.profileInfo);
  const employerDashboard = useSelector(
    (state: any) => state.employer.dashboard
  );

  const [formFields, setFormFields] = useState<any>({});
  const [sharedFields, setSharedFields] = useState<any>({});
  const [jobId, setJobId] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("edit");

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setIsMobileView(window.innerWidth < 700);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const defaultStyle = {
    display: "flex",
    justifyContent: "space-between",
  };

  const mediaQueryStyle = {
    ...defaultStyle,
    gap: "10px", // Add other styles you want to change for the media query
    flexDirection: windowWidth <= 980 ? "column" : "row",
  };

  const divStyle = windowWidth <= 1422 ? mediaQueryStyle : defaultStyle;

  // LifeCylce events
  useEffect(() => {
    setLoading(true);

    if (profileInfo?.first_name && employerDashboard?.job_statistics) {
      setLoading(false);
    }
  }, [profileInfo, employerDashboard]);

  const internalFunc = async () => {
    const sharedFieldsValues: any = {};
    const promises: any = [];
    [
      "education-level/",
      "experience-level/",
      "skill",
      "industry",
      "work-locations/",
      "role-type/",
    ].forEach(async (elem) => {
      promises.push(
        axios.get(`${api}/shared/${elem}`, {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
      );
    });
    let allData = await Promise.all(promises);
    allData = allData.map((allData) => allData.data);

    setSharedFields({
      education: allData[0],
      experience: allData[1],
      skill: allData[2],
      industry: allData[3],
      workPlaceType: allData[4],
      jobType: allData[5],
    });
  };

  useEffect(() => {
    internalFunc();
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getJob(id)).then((data) => {
        const payload = data.payload;
        setValue("title", payload.title);
        setValue("salary_range_to", payload.salary_range.to);
        setValue("salary_range_from", payload.salary_range.from);
        setValue("location", payload.location);
        setValue("job_description", stripHtml(payload.job_description));
        setValue("job_type", [payload.employment_type[0].id]);
        setValue("experience_required", [payload.seniority_level[0].id]);
        setValue("industry", [payload.industry[0].id]);
        setValue("education", [payload.education_level[0].id]);
        setValue("work_place_type", [payload.workplace[0].id]);
        const skills = payload.skills.map((skill: any) => skill.id);
        setValue("skills", skills);
      });
    }
  }, [id]);

  const { register, handleSubmit, reset, formState, setValue, getValues } =
    useForm({
      mode: "onChange",
    });

  const { isValid } = formState;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setSubmitLoading(true);
    const payload: any = {};
    const _data = { ...data, ...formFields };

    _data["employer"] = employerDashboard?.profile?.id;

    if (!id) {
      const accessToken = getAccessToken();

      try {
        // First API call to post the job
        const postJobResponse = await axios.post(`${api}/job/manage/`, _data, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });
        message.success("Job Posted Successfully");
        // Assuming postJobResponse.data contains the job_id
        setJobId(postJobResponse.data.id);
        // Second API call to get client secret
        const clientSecretResponse = await axios.post(
          `${api}/main/stripe/create_checkout/job/`,
          {
            job_id: postJobResponse.data.id,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // Extracting clientSecret and updating state
        const clientSecret = clientSecretResponse.data.client_secret;
        setClientSecret(clientSecret);
        navigate("/payment", {
          state: clientSecretResponse.data.client_secret,
        });
      } catch (error) {
        console.error("Error:", error);
        if (error.response.status === 403) {
          message.error(error.response.data.message);
        } else if (error.response.status === 400) {
          message.error(error.response.data.message);
        }
      } finally {
        setSubmitLoading(false);
        reset();
      }
    } else {
      dispatch(updateJob({ ..._data, id })).then(() => {
        message.success("Job Posted Successfully");
        setSubmitLoading(false);
        reset();
        navigate("/post/job");
      });
    }
  };

  const onFieldChange = (fieldKey: string, value: string | number) => {
    if (!value) return;
    const fieldToSetInState = {
      [fieldKey]: Array.isArray(value) ? value : [value],
    };
    setFormFields({ ...formFields, ...fieldToSetInState });
  };

  return (
    <AnimatePresence>
      {!loading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="postjob__dashboard-box"
        >
          <DashboardNavbar addLogo={isMobileView} />
          <main className="postjob__dashboard">
            <motion.div
              key={"dashboard-heading"}
              transition={{ duration: 0.3, delay: 0.3 }}
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="postjob__dashboard-heading"
            >
              <h1>{id ? "Edit Job" : "Post Job"}</h1>
              <button
                onClick={() => navigate("/jobs-posted")}
                className="postjob__dashboard-button"
              >
                See Posted Jobs
              </button>
            </motion.div>
            <motion.section
              transition={{ duration: 0.3, delay: 0.4 }}
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className="postjob__dashboard-container"
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={divStyle}>
                  <label>
                    <span>Job Title</span>
                    <input
                      {...register("title", { required: true })}
                      type="text"
                    />
                  </label>

                  <label>
                    <span>Workplace Type</span>
                    <Select
                      optionFilterProp="label"
                      showSearch={true}
                      onChange={(value) => {
                        setValue("work_place_type", value);
                        onFieldChange("work_place_type", value);
                      }}
                      className="select"
                      placeholder="Select"
                      value={getValues("work_place_type")}
                    >
                      {sharedFields["workPlaceType"]?.map(
                        (job: { id: number; name: string }) => (
                          <Option
                            label={job.name}
                            value={job.id}
                            className="roleType__option"
                          >
                            {job.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </label>

                  <label>
                    <span>Job Type</span>
                    <Select
                      optionFilterProp="label"
                      showSearch={true}
                      onChange={(value) => {
                        setValue("job_type", value);
                        onFieldChange("job_type", value);
                      }}
                      placeholder="Select"
                      className="select"
                      value={getValues("job_type")}
                    >
                      {sharedFields["jobType"]?.map(
                        (job: { id: number; name: string }) => (
                          <Option
                            label={job.name}
                            value={job.id}
                            className="roleType__option"
                            key={job.id}
                          >
                            {job.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </label>
                </div>

                <div style={divStyle}>
                  <label>
                    <span>Experience</span>
                    <Select
                      optionFilterProp="label"
                      showSearch={true}
                      className="select"
                      placeholder="Select"
                      onChange={(value) => {
                        setValue("experience_required", value);
                        onFieldChange("experience_required", value);
                      }}
                      value={getValues("experience_required")}
                    >
                      {sharedFields["experience"]?.map(
                        (job: { id: number; name: string }) => (
                          <Option
                            label={job.name}
                            value={job.id}
                            className="roleType__option"
                            key={job.id}
                          >
                            {job.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </label>

                  <label>
                    <span>Education</span>
                    <Select
                      optionFilterProp="label"
                      showSearch={true}
                      className="select"
                      placeholder="Select"
                      onChange={(value) => {
                        setValue("education", value);
                        onFieldChange("education", value);
                      }}
                      value={getValues("education")}
                    >
                      {sharedFields["education"]?.map(
                        (job: { id: number; name: string }) => (
                          <Option
                            label={job.name}
                            value={job.id}
                            className="roleType__option"
                            key={job.id}
                          >
                            {job.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </label>

                  <label>
                    <span>Industry</span>
                    <Select
                      optionFilterProp="label"
                      showSearch={true}
                      className="select"
                      placeholder="Select"
                      onChange={(value) => {
                        setValue("industry", value);
                        onFieldChange("industry", value);
                      }}
                      value={getValues("industry")}
                    >
                      {sharedFields["industry"]?.map(
                        (job: { id: number; industry: string }) => (
                          <Option
                            label={job.industry}
                            value={job.id}
                            className="roleType__option"
                            key={job.id}
                          >
                            {job.industry}
                          </Option>
                        )
                      )}
                    </Select>
                  </label>
                </div>
                <div style={divStyle}>
                  <label>
                    <span>Skills</span>
                    <Select
                      optionFilterProp="label"
                      showSearch={true}
                      className="select"
                      placeholder="Select"
                      onChange={(value) => {
                        setValue("skills", value);
                        onFieldChange("skills", value);
                      }}
                      mode="multiple"
                      value={getValues("skills")}
                    >
                      {sharedFields["skill"]?.map(
                        (job: { id: number; skill: string }) => (
                          <Option
                            label={job.skill}
                            value={job.id}
                            className="roleType__option"
                            key={job.id}
                          >
                            {job.skill}
                          </Option>
                        )
                      )}
                    </Select>
                  </label>

                  <label>
                    <span>Salary Range From (annually)</span>
                    <input
                      {...register("salary_range_from", { required: true })}
                      type="number"
                    />
                  </label>

                  <label>
                    <span>Salary Range From (annually)</span>
                    <input
                      {...register("salary_range_to", { required: true })}
                      type="number"
                    />
                  </label>
                </div>
                <div style={divStyle}>
                  <label>
                    <span>Location</span>
                    <input
                      {...register("location", { required: true })}
                      type="text"
                    />
                  </label>
                </div>
                <div style={divStyle}>
                  <label style={{ width: "100%" }}>
                    <span>Add Description</span>
                    <textarea
                      className="input"
                      style={{ height: "100px" }}
                      {...register("job_description", { required: true })}
                    />
                  </label>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "40px",
                  }}
                >
                  <FormButton
                    text={`${id ? "Edit job" : "Post A New Job"}`}
                    disabled={
                      !getValues("salary_range_to") ||
                      !getValues("salary_range_from") ||
                      !getValues("job_description") ||
                      !getValues("location") ||
                      !getValues("title") ||
                      !getValues("industry") ||
                      !getValues("job_type") ||
                      !getValues("experience_required") ||
                      !getValues("education") ||
                      !(getValues("skills")?.length > 0) ||
                      !getValues("work_place_type")
                    }
                    loading={Boolean(submitLoading)}
                    customStyle={{
                      width: "200px",
                    }}
                  />
                  <FormButton
                    text="Integrate ATS"
                    loading={Boolean(submitLoading)}
                    customStyle={{
                      width: "200px",
                      background: "transparent",
                      color: "#1E1E2F",
                      border: "0.5px solid #1E1E2F",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate("/ats");
                      // message.info("Not Implemented yet");
                    }}
                  />
                </div>
              </form>
            </motion.section>
          </main>
        </motion.div>
      ) : (
        <TailSpin
          height="160"
          width="160"
          color="#FBAD84"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            justifySelf: "center",
            alignSelf: "center",
          }}
          wrapperClass="tail-spin"
          visible={true}
        />
      )}
    </AnimatePresence>
  );
};

export default EmployerDashboard;
