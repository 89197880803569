import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { getAccessToken } from "../../utils/jwtToken";
import api from "../../utils/api";
import Cookies from "js-cookie";
import { encryptPassword } from "../../utils/encryptPassword";
import { enc } from "crypto-js";
import { act } from "react-dom/test-utils";

// Interfaces
interface RegisterUserProps {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  repeatPassword: string;
  type: string;
  affiliateCode?: string;
}

interface VerifyOtpProps {
  email: string | undefined;
  otp: number;
}

interface ResendOtpProps {
  email: string | undefined;
}

interface LoginProps {
  email: string;
  password: string;
}

interface ForgetPasswordProps {
  email: string;
}

interface ResetPasswordProps {
  email: string;
  otp: number;
  passwordOne: string;
  passwordTwo: string;
}

// Helper function to handle API errors
const handleApiError = (err: any, rejectWithValue: any) => {
  if (!err.response) {
    throw err;
  }
  return rejectWithValue(err.response.data);
};

// Async thunks
export const registerUser = createAsyncThunk(
  "profile/registerUser",
  async (data: RegisterUserProps, { rejectWithValue }) => {
    try {
      const encryptedPassword = await encryptPassword(data.password);
      const encryptedRepeatPassword = await encryptPassword(
        data.repeatPassword
      );

      const payload = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password1: encryptedPassword,
        password2: encryptedRepeatPassword,
        role: data.type,
        ...(data.affiliateCode && { partner_affiliate: data.affiliateCode }),
      };

      const response = await axios.post(`${api}/accounts/register/`, payload);
      return response.data;
    } catch (err) {
      return handleApiError(err, rejectWithValue);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "profile/verifyOtp",
  async (data: VerifyOtpProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${api}/accounts/verify/`, {
        email: data.email,
        otp: data.otp,
      });
      return response.data;
    } catch (err) {
      return handleApiError(err, rejectWithValue);
    }
  }
);

export const resendOtp = createAsyncThunk(
  "profile/resendOtp",
  async (data: ResendOtpProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${api}/accounts/resend/`, {
        email: data.email,
      });
      return response.data;
    } catch (err: any) {
      return handleApiError(err, rejectWithValue);
    }
  }
);

export const loginUser = createAsyncThunk(
  "profile/loginUser",
  async (data: LoginProps, { rejectWithValue }) => {
    try {
      const encryptedPassword: any = await encryptPassword(data.password);

      const response = await axios.post(`${api}/accounts/login/`, {
        email: data.email,
        password: encryptedPassword,
      });
      return response.data;
    } catch (err: any) {
      return handleApiError(err, rejectWithValue);
    }
  }
);

export const loginAdmin = createAsyncThunk(
  "profile/loginAdminb",
  async (data: LoginProps, { rejectWithValue }) => {
    try {
      const encryptedPassword: any = await encryptPassword(data.password);

      const response = await axios.post(`${api}/adminpanel/login/`, {
        email: data.email,
        password: encryptedPassword,
      });
      return response.data;
    } catch (err: any) {
      return handleApiError(err, rejectWithValue);
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "profile/forgetPassword",
  async (data: ForgetPasswordProps, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${api}/accounts/password/reset/`, {
        email: data.email,
      });
      return response.data;
    } catch (err: any) {
      return handleApiError(err, rejectWithValue);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "profile/resetPassword",
  async (data: ResetPasswordProps, { rejectWithValue }) => {
    try {
      const encryptedPasswordOne: any = await encryptPassword(data.passwordOne);
      const encryptedPasswordTwo: any = await encryptPassword(data.passwordTwo);

      const response = await axios.post(
        `${api}/accounts/password/reset/confirm/`,
        {
          email: data.email,
          otp: data.otp,
          new_password1: encryptedPasswordOne,
          new_password2: encryptedPasswordTwo,
        }
      );
      return response.data;
    } catch (err: any) {
      return handleApiError(err, rejectWithValue);
    }
  }
);

export const googleAuthLogin = createAsyncThunk(
  "profile/googleAuthLogin",
  async (auth_token: string, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${api}/socialauth/google/`, {
        auth_token,
      });
      return response.data;
    } catch (err: any) {
      return handleApiError(err, rejectWithValue);
    }
  }
);

export const linkedInAuthLogin = createAsyncThunk(
  "profile/linkedInAuthLogin",
  async (auth_token: string, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${api}/socialauth/linkedin/`, {
        auth_token,
      });
      return response.data;
    } catch (err: any) {
      return handleApiError(err, rejectWithValue);
    }
  }
);

export const resetUserRole = createAsyncThunk(
  "profile/resetUserRole",
  async (role: string, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.post(
        `${api}/reset/role/`,
        {
          role,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return handleApiError(err, rejectWithValue);
    }
  }
);

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await axios.get(`${api}/accounts/user/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: any) {
      return handleApiError(err, rejectWithValue);
    }
  }
);

export const deleteProfile = createAsyncThunk(
  "profile/deleteProfile",
  async (reason: string, { rejectWithValue }) => {
    try {
      const accessToken = getAccessToken();
      const response = await fetch(`${api}/main/delete_account/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json", // Set the Content-Type to application/json
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ reason_rejection: reason }),
      });

      if (response.ok) {
        return await response.json();
      } else {
        // It's usually better to return rejectWithValue with some error information for asyncThunk
        return rejectWithValue(
          `Failed to delete profile: ${response.statusText}`
        );
      }
    } catch (err: any) {
      // When catching an error, use rejectWithValue to pass a custom payload as the rejected action payload
      return rejectWithValue(handleApiError(err, rejectWithValue));
    }
  }
);

const initialState = {
  accessToken: Cookies.get("access-token"),
  profileInfo: null,
  boardingCompleted:
    Cookies.get("boarding-completed") === "true" ? true : false,
  userRole: Cookies.get("role"),
  accountDeleteReason: "",
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateProfileState: (state, action: PayloadAction<any>) => {
      state.accessToken = action.payload.accessToken;
      state.userRole = action.payload.userRole;
      state.boardingCompleted = action.payload.boardingCompleted;
    },
    updateBoardingCompletion: (state) => {
      state.boardingCompleted = true;
    },
    updateAccountDeleteReason: (state, action: PayloadAction<any>) => {
      state.accountDeleteReason = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        registerUser.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      )
      .addCase(
        registerUser.rejected,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      );

    builder
      .addCase(
        verifyOtp.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      )
      .addCase(verifyOtp.rejected, (state: any, action: PayloadAction<any>) => {
        return action.payload;
      });

    builder
      .addCase(
        loginUser.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      )
      .addCase(loginUser.rejected, (state: any, action: PayloadAction<any>) => {
        return action.payload;
      });

    builder
      .addCase(
        forgetPassword.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      )
      .addCase(
        forgetPassword.rejected,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      );

    builder
      .addCase(
        resetPassword.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      )
      .addCase(
        resetPassword.rejected,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      );
    builder
      .addCase(
        googleAuthLogin.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      )
      .addCase(
        googleAuthLogin.rejected,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      );
    builder
      .addCase(
        linkedInAuthLogin.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      )
      .addCase(
        linkedInAuthLogin.rejected,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      );
    builder
      .addCase(
        resetUserRole.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      )
      .addCase(
        resetUserRole.rejected,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      );
    builder
      .addCase(
        getProfile.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.profileInfo = action.payload;
          // return action.payload;
        }
      )
      .addCase(
        getProfile.rejected,
        (state: any, action: PayloadAction<any>) => {
          return action.payload;
        }
      );
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {
  updateProfileState,
  updateBoardingCompletion,
  updateAccountDeleteReason,
} = profileSlice.actions;

export default profileSlice.reducer;
