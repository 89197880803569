import React, { useState } from "react";
import { Footer, FormButton, NavBar } from "../../components";
import {
  GoogleIcon,
  LinkedInIcon,
  PasswordHide,
  PasswordVisible,
} from "../../assets/icons";
import { SubmitHandler, useForm } from "react-hook-form";

import "./LoginScreen.scss";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../redux/store/store";
import {
  getProfile,
  linkedInAuthLogin,
  loginUser,
  resendOtp,
  updateProfileState,
} from "../../redux/ProfileSlice/ProfileSlice";
import { setJWTToken } from "../../utils/jwtToken";
import { useGoogleAuth } from "../../hooks/useGoogleAuth";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { enqueueSnackbar } from "notistack";

interface LoginInputs {
  email: string;
  password: string;
}

const LoginScreen = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<Boolean>(false);
  const [passwordHide, setPasswordHide] = useState<Boolean>(true);

  const [formData, setFormData] = useState({
    email: "",
  });

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm<LoginInputs>();

  const handleGoogleAuth = useGoogleAuth();

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID || "",
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "openid profile email",
    onSuccess: async (code: any) => {
      dispatch(linkedInAuthLogin(code)).then((res) => {
        if (res.type.includes("rejected")) {
          enqueueSnackbar(res.payload.detail, {
            variant: "error",
          });
        } else {
          setJWTToken(
            res.payload.access,
            res.payload.refresh,
            res.payload.user.role,
            res.payload.user.on_boarding_completed,
          );
          dispatch(
            updateProfileState({
              accessToken: res.payload.access,
              boardingCompleted: res.payload.user.on_boarding_completed,
              userRole: res.payload.user.role,
            }),
          );

          dispatch(getProfile());

          if (!res.payload.user.on_boarding_completed) {
            navigate(`/select-profile`);
          } else {
            navigate(`/dashboard`);
          }
        }
      });
    },
    onError: (error: any) => {},
  });

  const onSubmit: SubmitHandler<LoginInputs> = (data) => {
    setErrorMessage("");
    setLoading(true);
    dispatch(loginUser({ ...data })).then((res) => {
      setFormData(data);
      setLoading(false);
      if (res.type.includes("rejected")) {
        const errMessage: any = Object.values(res.payload);
        if (errMessage[0][0]?.length > 2) {
          setErrorMessage(errMessage[0][0]);
        } else {
          setErrorMessage("Something went wrong, please try again.");
        }
      } else {
        setJWTToken(
          res.payload.access,
          res.payload.refresh,
          res.payload.user.role,
          res.payload.user.on_boarding_completed,
        );

        dispatch(
          updateProfileState({
            accessToken: res.payload.access,
            boardingCompleted: res.payload.user.on_boarding_completed,
            userRole: res.payload.user.role,
          }),
        );

        dispatch(getProfile());
        if (
          !res.payload.user.on_boarding_completed &&
          res.payload.user.role === "partner"
        ) {
          navigate(`/complete-partner`);
        } else if (!res.payload.user.on_boarding_completed) {
          navigate(`/onboarding`);
        } else {
          navigate(`/dashboard`);
        }
        // return;d
      }
    });
  };

  const handleVerifyEmail = () => {
    setLoading(true);
    dispatch(resendOtp({ email: formData.email })).then((res) => {
      setLoading(false);
      if (res.type.includes("rejected")) {
        const errMessage: any = Object.values(res.payload);
        if (errMessage[0][0]?.length > 2) {
          setErrorMessage(errMessage[0][0]);
        } else {
          setErrorMessage("Something went wrong, please try again.");
        }
      } else {
        navigate(`/verify-otp/${formData.email}`);
        // return;
      }
    });
  };

  return (
    <>
      <div className="login">
        <NavBar />
        <div className="login__container">
          <h2>Welcome Back!</h2>
          <p>
            Access Your Personalized Dashboard and Engage with a Thriving Tech
            Community
          </p>
          <div className="signUp__container-social">
            {/* <button onClick={handleGoogleAuth}> */}
            <button onClick={handleGoogleAuth}>
              <GoogleIcon />
              Continue with Google
            </button>
            <button onClick={linkedInLogin}>
              <LinkedInIcon />
              Continue with LinkedIn
            </button>
          </div>
          <div className="signUp__container-seperator">
            <span></span>
            OR
            <span></span>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <label>
              <span>Email Address</span>
              <input required {...register("email")} type="email" />
            </label>
            <label>
              <span>
                Password
                <span onClick={() => setPasswordHide(!passwordHide)}>
                  {passwordHide ? <PasswordHide /> : <PasswordVisible />}
                </span>
              </span>
              <input
                required
                {...register("password")}
                type={passwordHide ? "password" : "text"}
              />
            </label>
            {errorMessage && (
              <span className="errorMessage">{errorMessage}</span>
            )}
            <FormButton text="Login" loading={Boolean(loading)} />
            <div className="login__container-links">
              <Link to={"/reset-password"}>Forgot Password</Link>
              {errorMessage === "E-mail is not verified." && (
                <span className="verify" onClick={handleVerifyEmail}>
                  Verify Email
                </span>
              )}
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default LoginScreen;
